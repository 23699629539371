import React from "react";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import { amplitude } from "./Amplitude";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const types = [
  {
    value: "Recruiter",
    label: "Recruiter",
  },
  {
    value: "Job Seeker",
    label: "Job Seeker",
  },
];

// Create a custom theme
const theme = createTheme({
  components: {
    Typography: {
      styleOverrides: {
        root: {
          fontFamily: "Urbanist", // Your desired fontFamily
          fontSize: "14px", // Your desired fontSize
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "Urbanist", // Your desired fontFamily
          fontSize: "14px", // Your desired fontSize
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Target the root and change the border color
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black", // Change to your desired color
          },
        },
      },
    },
  },
});
const AuthComponent = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [active, setActive] = useState("login");
  const [errorMessage, setErrorMessage] = useState("");
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [accountType, setAccountType] = useState("Recruiter");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const isPasswordValid = () => {
    const passwordPattern =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordPattern.test(password)) {
      setErrorMessage(
        "Password must be at least 8 characters long, contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return false;
    }
    return true;
  };

  const handleLoginPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (email && password) {
        handleLogin();
      }
    }
  };

  const handlePasswordResetPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (email) {
        handlePasswordReset();
      }
    }
  };

  const handleSignUpPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter" && !showLoading) {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (
        email &&
        password &&
        confirmPassword &&
        firstName &&
        lastName &&
        accountType
      ) {
        handleSignUp();
      }
    }
  };

  /**
   * Step 1: Send the user an email to get a password reset token.
   * This email contains a link which sends the user back to your application.
   */
  const handlePasswordReset = async () => {
    if (email === "") {
      setErrorMessage("Email is missing");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: "https://aestheticsportal.com/update-password",
    });

    if (error) {
    } else {
      setShowEmailConfirmation(true);
      amplitude.logEvent("PASSWORD_RESET_TRIGGER", { email: email });
      setTimeout(() => {
        setShowEmailConfirmation(false);
      }, 3000);
    }
  };
  /**
   * Step 2: Once the user is redirected back to your application,
   * ask the user to reset their password.
   */
  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        const newPassword = prompt(
          "What would you like your new password to be?"
        );
        const { data, error } = await supabase.auth.updateUser({
          password: newPassword,
        });

        if (data) alert("Password updated successfully!");
        if (error) alert("There was an error updating your password.");
      }
    });
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSignUp = async () => {
    setShowLoading(true);
    if (
      email === "" ||
      confirmPassword === "" ||
      password === "" ||
      firstName === "" ||
      lastName === ""
    ) {
      setErrorMessage("All fields are required");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setShowLoading(false);
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }

    if (!isPasswordValid()) {
      return;
    }

    try {
      const response = await fetch(
        "https://urdyzgbzu3.execute-api.us-west-2.amazonaws.com/prod/customer",
        {
          method: "POST",
          body: JSON.stringify({
            name:
              capitalizeFirstLetter(firstName) +
              " " +
              capitalizeFirstLetter(lastName),
            email: email,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      const { user, session, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            first_name: capitalizeFirstLetter(firstName),
            last_name: capitalizeFirstLetter(lastName),
            recruiter: accountType === "Recruiter" ? true : false,
            stripe_id: result.id,
          },
        },
      });

      if (error) {
        setErrorMessage("Error signing up");
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      } else {
        setActive("confirm_email");
        amplitude.logEvent("SIGN_UP", { email: email });
        setShowLoading(false);
      }
    } catch (error) {
      console.error("Create stripe customer failed:", error);
    }
  };

  const handleLogin = async () => {
    if (email === "" || password === "") {
      setErrorMessage("Email or password is missing");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }

    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      setErrorMessage("Incorrect email or password");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    } else {
      amplitude.logEvent("SIGN_IN", { email: email });
      amplitude.setUserId(email);
      navigate("/");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          justifyItems: "center",
          height: "100vh",
        }}
      >
        <div className="auth-container">
          <img
            alt="logo"
            src="/images/Logo.png"
            style={{
              display: { xs: "none", md: "flex" },
              marginRight: "10px",
              width: 70,
              height: 50,
              marginBottom: "30px",
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={() => navigate("/")}
          ></img>
          {active === "login" && (
            <React.Fragment>
              <div className="auth-label-wrapper">
                <div className="auth-label">Email</div>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="auth-label-wrapper">
                <div className="auth-label">Password</div>
                <TextField
                  onKeyDown={handleLoginPress}
                  fullWidth
                  type="password"
                  size="small"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button onClick={handleLogin} className="auth-login-btn">
                Log In
              </button>
              {errorMessage && (
                <div className="auth-error-msg">{errorMessage}</div>
              )}
              <div
                onClick={() => setActive("forgot_pass")}
                className="auth-bottom-nav"
              >
                Forgot password?
              </div>
              <div
                onClick={() => setActive("signup")}
                className="auth-bottom-nav"
              >
                Create an account
              </div>
            </React.Fragment>
          )}
          {active === "signup" && (
            <React.Fragment>
              <div className="auth-signup-row">
                <div className="auth-label-wrapper">
                  <div className="auth-label">First Name</div>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="auth-label-wrapper">
                  <div className="auth-label">Last Name</div>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="auth-signup-row">
                <div className="auth-label-wrapper">
                  <div className="auth-label">Email</div>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="auth-label-wrapper">
                  <div className="auth-label">Account Type</div>
                  <Select
                    fullWidth
                    size="small"
                    value={accountType}
                    onChange={(e) => setAccountType(e.target.value)}
                  >
                    {types.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="auth-signup-row">
                <div className="auth-label-wrapper">
                  <div className="auth-label">Password</div>
                  <TextField
                    fullWidth
                    type="password"
                    size="small"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="auth-label-wrapper">
                  <div className="auth-label">Confirm Password</div>
                  <TextField
                    onKeyDown={handleSignUpPress}
                    type="password"
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
              </div>

              <button
                disabled={showLoading}
                onClick={handleSignUp}
                className="auth-login-btn"
              >
                Create Account
              </button>
              {showLoading && <CircularProgress sx={{ color: "grey" }} />}
              {errorMessage && (
                <div className="auth-error-msg">{errorMessage}</div>
              )}
              <div
                onClick={() => setActive("login")}
                className="auth-bottom-nav"
              >
                Already have an account? Sign in
              </div>
              <div
                onClick={() => setActive("forgot_pass")}
                className="auth-bottom-nav"
              >
                Forgot password?
              </div>
            </React.Fragment>
          )}
          {active === "confirm_email" && (
            <React.Fragment>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "60px", color: "#6fce6f", marginTop: "10px" }}
              />
              <div className="auth-label">
                Confirmation email has been sent!
              </div>
              <div className="auth-label-spam">
                Please check spam if you don't see it in your inbox
              </div>
            </React.Fragment>
          )}
          {active === "forgot_pass" && (
            <React.Fragment>
              <div className="auth-label-wrapper">
                <div className="auth-label">Email</div>
                <TextField
                  onKeyDown={handlePasswordResetPress}
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button onClick={handlePasswordReset} className="auth-login-btn">
                Reset Password
              </button>
              {errorMessage && (
                <div className="auth-error-msg">{errorMessage}</div>
              )}
              {showEmailConfirmation && (
                <div className="auth-success-msg">
                  Check your email for a password reset link
                </div>
              )}
              <div
                onClick={() => setActive("login")}
                className="auth-bottom-nav"
              >
                Already have an account? Sign in
              </div>
              <div
                onClick={() => setActive("signup")}
                className="auth-bottom-nav"
              >
                Create an account
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AuthComponent;
