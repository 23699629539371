import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fetchPlace } from "./FetchPlace";
import Autocomplete from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material";
import { supabase } from "./SupabaseClient";
import dayjs from "dayjs";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

const stripePromise = loadStripe(
  "pk_live_51L8CaSKvsvXbaxMgDOF2oOUzhzWGjD53lzRJamrwKhJNj7HZ6CLL6Yn3u4qzx7s2dJf1QVotb5Upk5GrhfwC4gZt00nDF5SnFf"
);

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#000000",
  },
};

const stateAcronyms = [
  "AL", // Alabama
  "AK", // Alaska
  "AZ", // Arizona
  "AR", // Arkansas
  "CA", // California
  "CO", // Colorado
  "CT", // Connecticut
  "DE", // Delaware
  "FL", // Florida
  "GA", // Georgia
  "HI", // Hawaii
  "ID", // Idaho
  "IL", // Illinois
  "IN", // Indiana
  "IA", // Iowa
  "KS", // Kansas
  "KY", // Kentucky
  "LA", // Louisiana
  "ME", // Maine
  "MD", // Maryland
  "MA", // Massachusetts
  "MI", // Michigan
  "MN", // Minnesota
  "MS", // Mississippi
  "MO", // Missouri
  "MT", // Montana
  "NE", // Nebraska
  "NV", // Nevada
  "NH", // New Hampshire
  "NJ", // New Jersey
  "NM", // New Mexico
  "NY", // New York
  "NC", // North Carolina
  "ND", // North Dakota
  "OH", // Ohio
  "OK", // Oklahoma
  "OR", // Oregon
  "PA", // Pennsylvania
  "RI", // Rhode Island
  "SC", // South Carolina
  "SD", // South Dakota
  "TN", // Tennessee
  "TX", // Texas
  "UT", // Utah
  "VT", // Vermont
  "VA", // Virginia
  "WA", // Washington
  "WV", // West Virginia
  "WI", // Wisconsin
  "WY", // Wyoming
];

const theme = createTheme({
  components: {
    Typography: {
      styleOverrides: {
        root: {
          fontFamily: "Urbanist",
          fontSize: "14px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "Urbanist",
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
        },
      },
    },
  },
});

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#000000",
  },
  fontFamily: "Urbanist",
  fontSize: "12px",
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Urbanist !important",
  fontWeight: "300 !important",
  fontSize: "16px !important",
  marginRight: "20px",
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#000000",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const Settings = ({ user }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("account");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [state, setState] = useState("");
  const [instagram, setInstagram] = useState("");
  const [city, setCity] = useState("");
  const [autocompleteCities, setAutocompleteCities] = useState([]);
  const [autocompleteErr, setAutocompleteErr] = useState("");
  const [birthdate, setBirthdate] = useState(null);
  const [open, setOpen] = useState(false);
  const [billingHistory, setBillingHistory] = useState([]);
  const [billingLoading, setBillingLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [total, setTotal] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [showFreeCurrent, setShowFreeCurrent] = useState(false);
  const [showPremiumCurrent, setShowPremiumCurrent] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handlePaymentModalClose = () => {
    setShowPaymentModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (value === "billing") {
      getBillingHistory();
    }
  }, [value]);

  const getBillingHistory = async () => {
    setBillingLoading(true);
    const { data, error } = await supabase
      .from("Payments")
      .select()
      .eq("user_id", user.id)
      .order("created_at", { ascending: false });
    if (error) {
      console.error("Error fetching billing history:", error);
    } else {
      setBillingHistory(data);
      setBillingLoading(false);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="black"
        onClick={handleClose}
      >
        <CloseIcon sx={{ color: "white" }} fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (user) {
      if (user.premium) {
        setShowFreeCurrent(false);
        setShowPremiumCurrent(true);
      } else if (!user.premium) {
        setShowFreeCurrent(true);
        setShowPremiumCurrent(false);
      }
    }
  }, [user]);

  const addPaymentRecord = async () => {
    const { data, error } = await supabase.from("Payments").insert([
      {
        user_id: user.id,
        amount: 30,
        product: "Monthly Premium Subscription",
        email: user.email,
      },
    ]);

    if (error) {
      console.error("Error adding payment record:", error);
    }
  };

  const options = {
    clientSecret,
    appearance,
  };

  const upgradeToPremium = async () => {
    const { data, error } = await supabase
      .from("Users")
      .update({ premium: true, subscription_id: subscriptionId })
      .eq("id", user.id);
    if (error) {
      console.error("Error updating user:", error);
    } else {
      addPaymentRecord();
      setShowPaymentModal(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await fetch(
        "https://urdyzgbzu3.execute-api.us-west-2.amazonaws.com/prod/cancel",
        {
          method: "POST",
          body: JSON.stringify({ id: user.subscription_id }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      const { data, error } = await supabase
        .from("Users")
        .update({ premium: false, subscription_id: null })
        .eq("id", user.id);

      if (error) {
        console.error("Error updating user:", error);
      }
      setShowFreeCurrent(true);
      setShowPremiumCurrent(false);
      setShowCancelModal(false);
    } catch (error) {
      console.error("Cancel failed:", error);
    }
  };

  const handleOpenCancelModal = () => {
    setShowCancelModal(true);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handlePaymentModalOpen = () => {
    fetchClientSecret();
  };

  const fetchClientSecret = async () => {
    try {
      const response = await fetch(
        "https://urdyzgbzu3.execute-api.us-west-2.amazonaws.com/prod/subscription",
        {
          method: "POST",
          body: JSON.stringify({ customerId: user.stripe_id }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setClientSecret(result.clientSecret);
      setSubscriptionId(result.subscriptionId);
      setShowPaymentModal(true);
    } catch (error) {
      console.error("Create session failed:", error);
    }
  };

  useEffect(() => {
    if (user) {
      if (user.first_name) setFirstName(user.first_name);
      if (user.last_name) setLastName(user.last_name);
      if (user.birthdate) setBirthdate(user.birthdate);
      if (user.location_city) setCity(user.location_city);
      if (user.location_state) setState(user.location_state);
      if (user.instagram) setInstagram(user.instagram);
    }
  }, []);

  const handleCityChange = async (value) => {
    setCity(value);
    if (!value) return; // Use the argument `value` instead of `location` for immediate effect

    try {
      const res = await fetchPlace(value);

      if (res.features) {
        // Extract city names from the response
        const cityNames = res.features.map((place) => place.text); // Using `text` for city names

        // Update autocomplete suggestions
        // Ensure no duplicates are added, assuming `autocompleteCities` is a state variable
        const newCities = cityNames.filter(
          (city) => !autocompleteCities.includes(city)
        );
        setAutocompleteCities((prevCities) => [...prevCities, ...newCities]);
      }
    } catch (error) {
      console.error("Failed to fetch places:", error);
      setAutocompleteErr("Failed to fetch places");
    }
  };

  const saveAccountDetails = async () => {
    if (!firstName || !lastName || !birthdate || !city || !state) {
      alert("Please fill out all required fields");
      return;
    }

    const { data, error } = await supabase
      .from("Users")
      .update({
        first_name: firstName,
        last_name: lastName,
        birthdate: birthdate,
        location_city: city,
        location_state: state,
        instagram: instagram,
      })
      .eq("id", user.id);
    if (error) {
      console.error("Error updating user:", error);
    } else {
      setOpen(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "auto", paddingLeft: "20px", paddingRight: "40px" }}>
        <StyledTabs value={value} onChange={handleChange}>
          <StyledTab value="account" label="Account" />
          <StyledTab value="plans" label="Plans" />
          <StyledTab value="billing" label="Billing History" />
        </StyledTabs>
        <div className="dashboard-settings-container">
          {value === "account" && (
            <div className="dashboard-settings">
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <div className="settings-label-wrapper">
                    <div className="settings-label">First Name</div>
                    <div className="settings-required">* required</div>
                  </div>
                  <TextField
                    size="small"
                    fullWidth
                    required
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className="settings-label-wrapper">
                    <div className="settings-label">Last Name</div>
                    <div className="settings-required">* required</div>
                  </div>{" "}
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className="settings-label-wrapper">
                    <div className="settings-label">Birthdate</div>
                    <div className="settings-required">* required</div>
                  </div>{" "}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(birthdate)}
                      onChange={(newValue) => {
                        setBirthdate(newValue);
                      }}
                      slotProps={{ textField: { size: "small" } }}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <div className="settings-label-wrapper">
                    <div className="settings-label">City</div>
                    <div className="settings-required">* required</div>
                  </div>{" "}
                  <Autocomplete
                    freeSolo
                    size="small"
                    fullWidth
                    value={city}
                    components={{
                      EndAdornment: () => null, // This removes the end adornment
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleCityChange(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                      setCity(newValue);
                    }}
                    options={autocompleteCities}
                    renderInput={(params) => (
                      <TextField size="small" {...params} fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <div className="settings-label-wrapper">
                    <div className="settings-label">State</div>
                    <div className="settings-required">* required</div>
                  </div>{" "}
                  <Autocomplete
                    fullWidth
                    freeSolo
                    size="small"
                    onChange={(event, newValue) => {
                      setState(newValue);
                    }}
                    value={state}
                    options={stateAcronyms}
                    renderInput={(params) => (
                      <TextField size="small" {...params} />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <div className="settings-label-wrapper">
                    <div className="settings-label">Instagram</div>
                    <div className="settings-required">* optional</div>
                  </div>{" "}
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <button
                    onClick={saveAccountDetails}
                    className="settings-save-button"
                  >
                    Save
                  </button>
                  <Snackbar
                    open={open}
                    autoHideDuration={1000}
                    onClose={handleClose}
                    message="Details saved"
                    action={action}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          {value === "plans" && (
            <div className="plans-container">
              <div className="card" style={{ height: "190px" }}>
                <div>
                  <div className="card-title-wrapper">
                    <div className="card-title">Free</div>
                    <div className="card-subtitle">forever</div>
                  </div>
                  <Divider
                    sx={{
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    flexItem
                  />
                  <div className="price-option-container">
                    <div className="price-option">
                      <Avatar
                        sx={{
                          bgcolor: "#000000",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <CheckIcon sx={{ width: "10px", height: "10px" }} />
                      </Avatar>
                      10 applications per month
                    </div>{" "}
                  </div>
                </div>
                {showFreeCurrent ? (
                  <button className="current-button">Current</button>
                ) : (
                  <button
                    onClick={handleOpenCancelModal}
                    className="upgrade-button"
                  >
                    Downgrade
                  </button>
                )}
              </div>
              <div className="card" style={{ height: "190px" }}>
                <div>
                  <div className="card-title-wrapper">
                    <div className="card-title">$30</div>
                    <div className="card-subtitle">per month</div>
                  </div>
                  <Divider
                    sx={{
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    flexItem
                  />
                  <div className="price-option-container">
                    <div className="price-option">
                      <Avatar
                        sx={{
                          bgcolor: "#000000",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <CheckIcon sx={{ width: "10px", height: "10px" }} />
                      </Avatar>
                      Show up first to employers
                    </div>
                    <div className="price-option">
                      <Avatar
                        sx={{
                          bgcolor: "#000000",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                        <CheckIcon sx={{ width: "10px", height: "10px" }} />
                      </Avatar>
                      Unlimited applications
                    </div>
                  </div>
                </div>
                {showPremiumCurrent ? (
                  <button className="current-button">Current</button>
                ) : (
                  <button
                    onClick={handlePaymentModalOpen}
                    className="upgrade-button"
                  >
                    Upgrade
                  </button>
                )}
              </div>
            </div>
          )}
          {value === "billing" && (
            <div className="billing-container">
              <div className="table-row">
                <Divider orientation="vertical" flexItem />
                <div className="table-label">Date</div>
                <Divider orientation="vertical" flexItem />
                <div className="table-label">Product</div>
                <Divider orientation="vertical" flexItem />
                <div className="table-label">Amount</div>
              </div>

              {billingLoading && <div>Loading...</div>}
              {!billingLoading &&
                billingHistory.map((item) => {
                  return (
                    <div className="table-row" key={item.id}>
                      <div className="item-label">
                        {dayjs(item.created_at).format("M/D/YY")}
                      </div>
                      <div className="item-label">{item.product}</div>{" "}
                      <div className="item-label">${item.amount}</div>
                    </div>
                  );
                })}
            </div>
          )}
          <Dialog
            sx={{
              "& .MuiDialog-paper": {
                width: "100%",
                maxWidth: "900px",
                height: "100%",
                maxHeight: "550px",
                padding: "16px",
                borderRadius: "20px",
              },
              "& .MuiBackdrop-root": {
                backgroundColor: "rgb(0 0 0 / 50%)", // Adjust backdrop opacity here
              },
            }}
            open={showPaymentModal}
            onClose={handlePaymentModalClose}
          >
            <DialogTitle
              sx={{
                fontFamily: "Urbanist",
                display: "flex",
                gap: "20px",
              }}
            >
              Payment
            </DialogTitle>

            <DialogContent>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm upgradeToPremium={upgradeToPremium} />
              </Elements>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <div>
                {" "}
                <Button
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                  onClick={handlePaymentModalClose}
                >
                  Cancel
                </Button>
              </div>
            </DialogActions>
          </Dialog>
          <Dialog
            sx={{
              "& .MuiDialog-paper": {
                width: "100%",
                maxWidth: "600px",
                height: "100%",
                maxHeight: "180px",
                padding: "16px",
                borderRadius: "20px",
              },
              "& .MuiBackdrop-root": {
                backgroundColor: "rgb(0 0 0 / 50%)", // Adjust backdrop opacity here
              },
            }}
            open={showCancelModal}
            onClose={handleCloseCancelModal}
          >
            <DialogTitle
              sx={{
                fontFamily: "Urbanist",
                display: "flex",
                gap: "20px",
              }}
            >
              Cancel Subscription
            </DialogTitle>

            <DialogContent>
              <div className="cancel-label">
                Are you sure you want to cancel your premium subscription? If
                yes, your current subscription will be canceled at the end of
                the current billing cycle.{" "}
              </div>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
              }}
            >
              <div>
                {" "}
                <Button
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                  onClick={handleCancelSubscription}
                >
                  Yes
                </Button>
                <Button
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                  onClick={handleCloseCancelModal}
                >
                  No
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default Settings;
