export const fetchPlace = async (text) => {
  try {
    const res = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?country=us&limit=3&proximity=ip&types=place&language=en&autocomplete=true&access_token=${process.env.REACT_APP_MAP_API_KEY}`
    );
    if (!res.ok) throw new Error(res.statusText);
    return res.json();
  } catch (err) {
    return { error: "Unable to retrieve places" };
  }
};
