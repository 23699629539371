import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#000000",
  },
  fontFamily: "Urbanist",
  fontSize: "12px",
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Urbanist !important",
  fontWeight: "300 !important",
  fontSize: "16px !important",
  marginRight: "20px",
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#000000",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const Jobs = ({ user }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("applied");
  const [favorites, setFavorites] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getFavorites = async (ids) => {
    const { data, error } = await supabase.from("Jobs").select().in("id", ids);
    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setFavorites(data);
    }
  };

  const getAppliedJobs = async (id) => {
    const { data, error } = await supabase
      .from("Applications")
      .select(
        `
      Jobs ( id, job_name, company_name, job_location_city, job_location_state, job_type, created_at)
    `
      )
      .eq("user_id", id);
    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setAppliedJobs(data);
    }
  };

  const updateFavoritesInSupabase = async (newFavorites) => {
    // Assuming you have the user's ID stored in a variable `userId`
    const { data, error } = await supabase
      .from("Users")
      .update({ favorites: newFavorites })
      .eq("id", user.id); // Make sure 'id' matches the primary key column name in your Users table

    if (error) {
      throw new Error("Error updating favorites in Supabase:", error.message);
    }
    return data;
  };

  const removeFromFavorites = (id) => {
    setFavorites((prevFavorites) => {
      if (prevFavorites.hasOwnProperty(id)) {
        // Create a new object without the specified id
        const { [id]: value, ...newFavorites } = prevFavorites;
        // Update Supabase after state update
        updateFavoritesInSupabase(newFavorites).catch((error) => {
          console.error("Error updating favorites in Supabase:", error);
        });
        return newFavorites;
      } else {
        return prevFavorites;
      }
    });
  };

  const navigateToJobPage = (id) => {
    navigate(`/job/${id}`);
  };

  useEffect(() => {
    if (user) {
      getAppliedJobs(user.id);
    }
    if (user && user.favorites) {
      const favoriteIds = Object.keys(user.favorites).filter(
        (key) => user.favorites[key]
      );

      if (favoriteIds.length === 0) {
        setFavorites([]); // Clear the favorites if there are none
        return;
      } else {
        getFavorites(favoriteIds);
      }
    }
  }, []);

  return (
    <Box sx={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab value="applied" label="Applied" />
        <StyledTab value="saved" label="Saved" />
      </StyledTabs>

      <div className="dashboard-jobs-container">
        {value === "applied" && (
          <div className="dashboard-jobs">
            {appliedJobs &&
              appliedJobs.length > 0 &&
              appliedJobs.map(({ Jobs }) => (
                <div className="job" key={Jobs.id}>
                  <div>
                    <div className="job-name">{Jobs.job_name}</div>
                    <div className="job-company-container">
                      <BusinessIcon
                        sx={{ width: "16px", color: "gray" }}
                      ></BusinessIcon>
                      <div className="job-company">{Jobs.company_name}</div>
                    </div>
                    <div className="job-location-container">
                      <FmdGoodIcon
                        sx={{ width: "16px", color: "gray" }}
                      ></FmdGoodIcon>
                      <div className="job-location">
                        {Jobs.job_location_city}, {Jobs.job_location_state}
                      </div>
                    </div>
                    <div className="job-date-container">
                      <CalendarTodayIcon
                        sx={{ width: "16px", color: "gray" }}
                      />
                      <div className="job-date">
                        {dayjs(Jobs.createdAt).format("M/D/YY")}
                      </div>
                    </div>

                    <div className="job-type-container">
                      {Jobs.job_type.map((type) => {
                        return (
                          <div key={type} className="job-chip">
                            {type}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="job-footer">
                    <button
                      className="dashboard-btn"
                      onClick={() => navigateToJobPage(Jobs.id)}
                    >
                      Details
                    </button>
                  </div>
                </div>
              ))}
            {!appliedJobs.length && (
              <div className="no-jobs-text">No applied jobs</div>
            )}
          </div>
        )}
        {value === "saved" && (
          <div className="dashboard-jobs">
            {favorites &&
              favorites.length > 0 &&
              favorites.map((job) => (
                <div className="job" key={job.id}>
                  <div>
                    <div className="job-name">{job.job_name}</div>
                    <div className="job-company-container">
                      <BusinessIcon
                        sx={{ width: "16px", color: "gray" }}
                      ></BusinessIcon>
                      <div className="job-company">{job.company_name}</div>
                    </div>
                    <div className="job-location-container">
                      <FmdGoodIcon
                        sx={{ width: "16px", color: "gray" }}
                      ></FmdGoodIcon>
                      <div className="job-location">
                        {job.job_location_city}, {job.job_location_state}
                      </div>
                    </div>
                    <div className="job-date-container">
                      <CalendarTodayIcon
                        sx={{ width: "16px", color: "gray" }}
                      />
                      <div className="job-date">
                        {dayjs(job.createdAt).format("M/D/YY")}
                      </div>
                    </div>

                    <div className="job-type-container">
                      {job.job_type.map((type) => {
                        return (
                          <div key={type} className="job-chip">
                            {type}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="job-footer">
                    <FavoriteIcon
                      sx={{ color: "#ff7e7ed1" }}
                      onClick={() => removeFromFavorites(job.id)}
                    />

                    <button
                      className="dashboard-btn"
                      onClick={() => navigateToJobPage(job.id)}
                    >
                      Details
                    </button>
                  </div>
                </div>
              ))}
            {!favorites.length && (
              <div className="no-jobs-text">No saved jobs</div>
            )}
          </div>
        )}
      </div>
    </Box>
  );
};

export default Jobs;
