import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { fetchPlace } from "./FetchPlace";
import Autocomplete from "@mui/material/Autocomplete";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckoutForm from "./CheckoutForm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Radio from "@mui/material/Radio";
import dayjs from "dayjs";
import Select from "@mui/material/Select";

let Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = [
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
];
ReactQuill.Quill.register(Size, true);

const modules = {
  toolbar: [
    // ... (other toolbar options)
    [{ size: Size.whitelist }],
    [{ align: [] }], // Dropdown with text align options
    ["bold", "italic", "underline", "strike"], // Toggles for bold, italic, underline, and strikethrough
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const steps = ["Enter Job Info", "Choose Duration + Ads", "Payment"];

const theme = createTheme({
  components: {
    Typography: {
      styleOverrides: {
        root: {
          fontFamily: "Urbanist",
          fontSize: "14px",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          // Default state
          "&.Mui-active": {
            color: "black", // Active state color
          },
          "&.Mui-completed": {
            color: "black", // Completed state color
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: "Urbanist",
          fontWeight: "300 !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "Urbanist",
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
        },
      },
    },
  },
});

const types = [
  {
    value: "Full Time",
    label: "Full Time",
  },
  {
    value: "Part Time",
    label: "Part Time",
  },
  {
    value: "Contract",
    label: "Contract",
  },
  {
    value: "Internship",
    label: "Internship",
  },
];

const stateAcronyms = [
  "AL", // Alabama
  "AK", // Alaska
  "AZ", // Arizona
  "AR", // Arkansas
  "CA", // California
  "CO", // Colorado
  "CT", // Connecticut
  "DE", // Delaware
  "FL", // Florida
  "GA", // Georgia
  "HI", // Hawaii
  "ID", // Idaho
  "IL", // Illinois
  "IN", // Indiana
  "IA", // Iowa
  "KS", // Kansas
  "KY", // Kentucky
  "LA", // Louisiana
  "ME", // Maine
  "MD", // Maryland
  "MA", // Massachusetts
  "MI", // Michigan
  "MN", // Minnesota
  "MS", // Mississippi
  "MO", // Missouri
  "MT", // Montana
  "NE", // Nebraska
  "NV", // Nevada
  "NH", // New Hampshire
  "NJ", // New Jersey
  "NM", // New Mexico
  "NY", // New York
  "NC", // North Carolina
  "ND", // North Dakota
  "OH", // Ohio
  "OK", // Oklahoma
  "OR", // Oregon
  "PA", // Pennsylvania
  "RI", // Rhode Island
  "SC", // South Carolina
  "SD", // South Dakota
  "TN", // Tennessee
  "TX", // Texas
  "UT", // Utah
  "VT", // Vermont
  "VA", // Virginia
  "WA", // Washington
  "WV", // West Virginia
  "WI", // Wisconsin
  "WY", // Wyoming
];

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#000000",
  },
};

const stripePromise = loadStripe(
  "pk_live_51L8CaSKvsvXbaxMgDOF2oOUzhzWGjD53lzRJamrwKhJNj7HZ6CLL6Yn3u4qzx7s2dJf1QVotb5Upk5GrhfwC4gZt00nDF5SnFf"
);

const NewPost = ({ user }) => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [description, setDescription] = useState("");
  const [jobType, setJobType] = useState(["Full Time"]);
  const [autocompleteCities, setAutocompleteCities] = useState([]);
  const [autocompleteErr, setAutocompleteErr] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [selectedValue, setSelectedValue] = useState("60");
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [total, setTotal] = useState(500);
  const [discount, setDiscount] = useState("");
  const [showDiscountError, setShowDiscountError] = useState(false);
  const [showInvalidDiscountError, setShowInvalidDiscountError] =
    useState(false);

  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    let total;
    if (selectedValue === "30") {
      if (numberOfDays > 0) {
        total = 300 + numberOfDays * 20;
      } else {
        total = 300;
      }
    } else if (selectedValue === "60") {
      if (numberOfDays > 0) {
        total = 500 + numberOfDays * 20;
      } else {
        total = 500;
      }
    } else if (selectedValue === "90") {
      if (numberOfDays > 0) {
        total = 720 + numberOfDays * 20;
      } else {
        total = 720;
      }
    }

    setTotal(total);
  }, [selectedValue, numberOfDays]);

  const fetchClientSecret = async () => {
    try {
      const response = await fetch(
        "https://urdyzgbzu3.execute-api.us-west-2.amazonaws.com/prod/intent",
        {
          method: "POST",
          body: JSON.stringify({
            amount: Number(total),
            customer: user.stripe_id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setClientSecret(result.clientSecret);
    } catch (error) {
      console.error("Create session failed:", error);
    }
  };

  useEffect(() => {
    if (activeStep === 2) {
      fetchClientSecret();
    }
  }, [activeStep]);

  const applyDiscount = () => {
    if (discount.toUpperCase() === "FRIENDS100") {
      setTotal(0);
      setDiscount("");
      setShowDiscountError(false);
    } else {
      setShowInvalidDiscountError(true);
      setTimeout(() => {
        setShowInvalidDiscountError(false);
      }, 3000);
    }
  };

  const handleNext = () => {
    if (activeStep === 1 && discount !== "") {
      setShowDiscountError(true);
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 2) {
      setClientSecret("");
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const addPaymentRecord = async () => {
    let productInfo;
    let promoInfo;
    if (selectedValue === "30") {
      productInfo = "30 Day Listing";
    } else if (selectedValue === "60") {
      productInfo = "60 Day Listing";
    } else if (selectedValue === "90") {
      productInfo = "90 Day Listing";
    }

    if (numberOfDays > 0) {
      promoInfo = `${numberOfDays} day promotion`;
    }

    const { data, error } = await supabase.from("Payments").insert([
      {
        user_id: user.id,
        amount: total,
        product: promoInfo ? `${productInfo} + ${promoInfo}` : productInfo,
        email: user.email,
      },
    ]);

    if (error) {
      console.error("Error adding payment record:", error);
    }
  };

  const handleCityChange = async (value) => {
    setCity(value);
    if (!value) return; // Use the argument `value` instead of `location` for immediate effect

    try {
      const res = await fetchPlace(value);

      if (res.features) {
        // Extract city names from the response
        const cityNames = res.features.map((place) => place.text); // Using `text` for city names

        // Update autocomplete suggestions
        // Ensure no duplicates are added, assuming `autocompleteCities` is a state variable
        const newCities = cityNames.filter(
          (city) => !autocompleteCities.includes(city)
        );
        setAutocompleteCities((prevCities) => [...prevCities, ...newCities]);
      }
    } catch (error) {
      console.error("Failed to fetch places:", error);
      setAutocompleteErr("Failed to fetch places");
    }
  };

  const createListing = async () => {
    if (
      companyName === "" ||
      jobTitle === "" ||
      city === "" ||
      state === "" ||
      description === "" ||
      jobType === ""
    ) {
      alert("Please fill out all fields");
      return;
    }

    const today = dayjs();
    const expires = today.add(Number(selectedValue), "day");
    const { data, error } = await supabase
      .from("Jobs")
      .insert([
        {
          company_name: companyName,
          job_name: jobTitle,
          job_location_city: city,
          job_location_state: state,
          job_description: description,
          job_type: jobType,
          job_owner_id: user.id,
          active: true,
          expires_at: expires,
          promoted: numberOfDays > 0 ? true : false,
          promoted_expires_at:
            numberOfDays > 0 ? today.add(Number(numberOfDays), "day") : null,
        },
      ])
      .select();

    if (error) {
      console.error("Error creating listing:", error);
    } else {
      addPaymentRecord();
      navigate(`/job/${data[0].id}`);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="new-post">
        <div className="stepper-wrapper">
          {" "}
          <Stepper sx={{ width: "600px" }} activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel className="step-label" {...labelProps}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        {activeStep === 0 && (
          <React.Fragment>
            {" "}
            <Grid mt={1} container spacing={4}>
              <Grid item xs={4}>
                <div className="label">Company Name</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <div className="label">Job Title</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                />
                <div style={{ display: "flex", gap: "10px", width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "70%",
                    }}
                  >
                    {" "}
                    <div className="label">City</div>
                    <Autocomplete
                      freeSolo
                      size="small"
                      fullWidth
                      value={city}
                      components={{
                        EndAdornment: () => null, // This removes the end adornment
                      }}
                      onInputChange={(event, newInputValue) => {
                        handleCityChange(newInputValue);
                      }}
                      onChange={(event, newValue) => {
                        setCity(newValue);
                      }}
                      options={autocompleteCities}
                      renderInput={(params) => (
                        <TextField size="small" {...params} fullWidth />
                      )}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "30%",
                    }}
                  >
                    {" "}
                    <div className="label">State</div>
                    <Autocomplete
                      fullWidth
                      freeSolo
                      size="small"
                      onChange={(event, newValue) => {
                        setState(newValue);
                      }}
                      value={state}
                      options={stateAcronyms}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </div>
                </div>

                <div className="label">Employment Type</div>
                <Select
                  fullWidth
                  size="small"
                  multiple
                  select
                  value={jobType}
                  onChange={(e) => setJobType(e.target.value)}
                >
                  {types.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={8}>
                <div className="label">Description</div>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  value={description}
                  onChange={setDescription}
                  style={{ height: "260px" }}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {activeStep === 1 && (
          <div className="duration-wrapper">
            <div className="duration-column">
              <div className="duration-bubble">
                <div className="duration-label">30 Day Listing</div>
                <div className="duration-price">$300</div>

                <Radio
                  value="30"
                  checked={selectedValue === "30"}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    color: "black", // Default color
                    "&.Mui-checked": {
                      color: "black", // Color when checked
                    },
                  }}
                />
              </div>
              <div className="duration-bubble">
                <div className="duration-label">60 Day Listing</div>
                <div className="duration-price">$500</div>

                <Radio
                  value="60"
                  checked={selectedValue === "60"}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  sx={{
                    color: "black", // Default color
                    "&.Mui-checked": {
                      color: "black", // Color when checked
                    },
                  }}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
              <div className="duration-bubble">
                <div className="duration-label">90 Day Listing</div>
                <div className="duration-price">$720</div>

                <Radio
                  value="90"
                  checked={selectedValue === "90"}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  sx={{
                    color: "black", // Default color
                    "&.Mui-checked": {
                      color: "black", // Color when checked
                    },
                  }}
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                />
              </div>
            </div>
            <div className="ads">
              <div className="ads-label-wrapper">
                <div className="ads-label">Boost Your Job</div>
                <div className="ads-label-optional">*optional</div>
              </div>

              <div className="ads-label-price">Starting at $20/day</div>

              <div className="ads-bubble">
                <TextField
                  value={numberOfDays}
                  onChange={(e) => setNumberOfDays(e.target.value)}
                  type="number"
                  variant="standard"
                  sx={{
                    width: "80px",
                    // Target the input font size
                    "& .MuiInputBase-input": {
                      fontSize: "26px", // Adjust font size here
                    },
                    // Target the underline before (default state)
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "black", // Keep underline black
                    },
                    // Target the underline after (focused state)
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "black", // Keep underline black when focused
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <div className="days-label">Days</div>
              </div>
              <div className="ads-label-rec">
                We recommend at least 7 days to reach more applicants{" "}
              </div>
            </div>

            <div className="total-price">
              <div className="discount-wrapper">
                <TextField
                  size="small"
                  variant="outlined"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
                <button onClick={applyDiscount} className="discount-btn">
                  Apply
                </button>
              </div>
              {showInvalidDiscountError && (
                <div className="discount-error">Invalid code</div>
              )}
              {showDiscountError && (
                <div className="discount-error">
                  Please click apply for your discount
                </div>
              )}
              <div className="price-amount">Total ${total}</div>
            </div>
          </div>
        )}
        {activeStep === 2 && clientSecret && (
          <div style={{ height: "100%" }}>
            {" "}
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm createListing={createListing} />
            </Elements>
          </div>
        )}
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
              {activeStep > 0 && (
                <button
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  className="post-button"
                >
                  Back
                </button>
              )}

              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep == 0 && (
                <button className="post-button" onClick={handleNext}>
                  Next
                </button>
              )}
              {activeStep === 1 && total === 0 && (
                <button className="post-button" onClick={createListing}>
                  Complete
                </button>
              )}
              {activeStep === 1 && total > 0 && (
                <button className="post-button" onClick={handleNext}>
                  Next
                </button>
              )}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default NewPost;
