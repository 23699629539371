import React from "react";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import InstagramIcon from "@mui/icons-material/Instagram";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

const Applicants = ({ jobId, handleGoBack, id }) => {
  const navigate = useNavigate();
  const [applicants, setApplicants] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [applicantId, setApplicantId] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [user, setUser] = useState(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowDrawer(open);
  };

  const getUser = async (profileId) => {
    const { data, error } = await supabase
      .from("Users")
      .select()
      .eq("id", profileId);
    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setUser(data[0]);
      setShowDrawer(true);
    }
  };

  const goToInstagram = () => {
    window.open(user.instagram, "_blank");
  };

  const getApplicants = async (id) => {
    const { data, error } = await supabase
      .from("Applications")
      .select("created_at, Users (*)")
      .eq("job_id", id);

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      // Sort the data array so that premium users come first
      const sortedData = data.sort((a, b) => {
        // Assuming 'premium' is a boolean property of Users
        // Premium users first
        if (a.Users.premium && !b.Users.premium) {
          return -1; // a comes before b
        }
        if (!a.Users.premium && b.Users.premium) {
          return 1; // b comes before a
        }
        return 0; // no change in order
      });

      setApplicants(sortedData);
    }
  };

  const closeMessageModal = () => {
    setNewMessage("");
    setMessageModal(false);
  };

  const openMessageModal = (id) => {
    setApplicantId(id);
    setMessageModal(true);
  };

  const sendMessage = async () => {
    const { data, error } = await supabase.rpc("get_matching_thread", {
      first_id: id,
      second_id: applicantId,
    });

    if (error) {
      console.error("Error querying thread:", error);
      return;
    }

    if (data && data.length > 0) {
      const threadId = data[0].id;
      // Use different variable names to avoid shadowing
      const { data: messageData, error: messageError } = await supabase
        .from("Messages")
        .insert([
          {
            thread_id: threadId,
            owner_id: id,
            message: newMessage,
          },
        ]);

      if (messageError) {
        console.error("Error sending message:", messageError);
        return;
      }

      // Update the thread
      const { error: updateError } = await supabase
        .from("Threads")
        .update({ last_message: newMessage, updated_at: new Date() })
        .eq("id", threadId);

      if (updateError) {
        console.error("Error updating thread:", updateError);
        return;
      }

      closeMessageModal();
    } else {
      // Correctly handle insert to get the inserted data
      const { data: insertData, error: insertError } = await supabase
        .from("Threads")
        .insert([
          {
            recipient_id: applicantId,
            sender_id: id,
            last_message: newMessage,
          },
        ])
        .select();

      if (insertError) {
        console.error("Error creating thread:", insertError);
      }

      const threadId = insertData[0].id;

      const { data: insertUnreadData, error: insertUnreadError } =
        await supabase.from("Threads_Unread").insert([
          {
            user_id: applicantId,
            thread_id: threadId,
            unread: 0,
          },
        ]);

      const { data: unreadData, error: unreadError } = await supabase
        .from("Threads_Unread")
        .insert([
          {
            user_id: id,
            thread_id: threadId,
            unread: 0,
          },
        ]);

      // Insert the message after creating the thread
      const { error: messageError } = await supabase.from("Messages").insert([
        {
          thread_id: threadId,
          owner_id: id,
          message: newMessage,
        },
      ]);

      if (messageError) {
        console.error("Error sending message:", messageError);
        return;
      }

      closeMessageModal();
    }
  };

  useEffect(() => {
    if (jobId) {
      getApplicants(jobId);
    }
  }, []);

  return (
    <div style={{ width: "100%", marginTop: "20px" }}>
      <IconButton sx={{ mb: 3 }} onClick={handleGoBack}>
        <ArrowBackIcon sx={{ width: "30px", height: "30px" }} />
      </IconButton>
      <div className="applicant-container">
        {applicants &&
          applicants.length > 0 &&
          applicants.map((item) => (
            <div className="applicant" key={item.Users.id}>
              <div className="applicant-centered">
                <Avatar
                  className="avatar"
                  src={`https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${item.Users.id}/${item.Users.profile_picture}`}
                ></Avatar>
                {item.Users.first_name && (
                  <div className="applicant-name">
                    {item.Users.first_name} {item.Users.last_name}
                  </div>
                )}

                {item.Users.location_city && (
                  <div className="applicant-location-container">
                    <FmdGoodIcon
                      sx={{ width: "16px", color: "gray" }}
                    ></FmdGoodIcon>
                    <div className="applicant-location">
                      {item.Users.location_city}, {item.Users.location_state}
                    </div>
                  </div>
                )}

                <div className="applicant-date-container">
                  <div className="applicant-date">
                    Applied - {dayjs(item.created_at).format("M/D/YY")}
                  </div>
                  {item.Users.premium && (
                    <div className="premium-chip">Premium</div>
                  )}
                </div>
              </div>
              <div className="applicant-footer">
                <button
                  className="dashboard-btn"
                  onClick={() => getUser(item.Users.id)}
                >
                  View Profile
                </button>
                <button
                  className="dashboard-btn"
                  onClick={() => openMessageModal(item.Users.id)}
                >
                  Message
                </button>
                <Dialog
                  sx={{
                    "& .MuiDialog-paper": {
                      // Targeting the Dialog's paper component
                      width: "80%", // Adjust the size as needed
                      maxWidth: "600px", // Maximum size
                      padding: "10px", // Apply padding to the Dialog
                      borderRadius: "20px", // Optional: Round the corners
                    },
                  }}
                  open={messageModal}
                  onClose={closeMessageModal}
                >
                  <DialogTitle sx={{ fontFamily: "Urbanist" }}>
                    New Message
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={newMessage}
                      multiline
                      rows={4}
                      onChange={(e) => setNewMessage(e.target.value)}
                      sx={{
                        marginTop: "10px",
                        fontFamily: "Urbanist !important",
                        fontSize: "12px !important",
                        "& .MuiOutlinedInput-root": {
                          // Default border color
                          "& fieldset": {
                            borderColor: "black",
                          },
                          // Hover border color
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          // Focused border color
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                          "& .MuiInputBase-input": {
                            fontSize: "14px",
                            fontFamily: "Urbanist",
                          },
                        },
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      sx={{ fontFamily: "Urbanist", color: "black" }}
                      onClick={closeMessageModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={sendMessage}
                      sx={{ fontFamily: "Urbanist", color: "black" }}
                    >
                      Send
                    </Button>
                  </DialogActions>
                </Dialog>
                <Drawer
                  anchor={"right"}
                  open={showDrawer}
                  onClose={toggleDrawer(false)}
                  PaperProps={{ className: "profile-drawer" }}
                >
                  <Box className="profile" sx={{ padding: "30px !important" }}>
                    <div className="profile-bubble">
                      <div className="top-section"></div>
                      <div className="profile-container">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                          }}
                        >
                          <div className="img-container">
                            <div className="profile-img">
                              {user && user.profile_picture ? (
                                <Avatar
                                  className="avatar"
                                  src={`https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${user.id}/${user.profile_picture}`}
                                ></Avatar>
                              ) : (
                                <Avatar className="avatar">
                                  <PersonIcon className="avatar-img" />
                                </Avatar>
                              )}
                            </div>
                          </div>

                          <div className="profile-details">
                            {user && (user.first_name || user.last_name) && (
                              <div className="name">
                                {user.first_name && `${user.first_name} `}
                                {user.last_name && user.last_name}
                              </div>
                            )}

                            {user &&
                              (user.location_city || user.location_state) && (
                                <div className="location">
                                  {user.location_city &&
                                    `${user.location_city}, `}
                                  {user.location_state && user.location_state}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="profile-icons-container">
                          {user && user.instagram && (
                            <IconButton
                              sx={{
                                backgroundColor: "#fafbfc",
                                mt: 4,
                                mr: 2,
                                width: "1.5rem",
                                height: "1.5rem",
                                boxShadow: "0px 0px 8px #00000026",
                              }}
                              color="black"
                              onClick={goToInstagram}
                            >
                              <InstagramIcon sx={{ fontSize: "1rem" }} />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="bubble">
                      <div className="resume">
                        <div className="header">
                          <div className="title">About Me</div>
                        </div>

                        {user && !user.about_me && (
                          <div className="body">No summary to show</div>
                        )}
                        {user && user.about_me && (
                          <div className="body">{user.about_me}</div>
                        )}
                      </div>
                    </div>
                    <div className="bubble">
                      <div className="resume">
                        <div className="header">
                          <div className="title">Experience</div>
                        </div>

                        {user &&
                          (!user.experience || user.experience.length < 1) && (
                            <div className="body">No experience to show</div>
                          )}
                        {user &&
                          user.experience &&
                          user.experience.length > 0 &&
                          user.experience.map((role) => {
                            return (
                              <div key={role.title} className="experience">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="role">{role.title}</div>
                                </div>
                                <div className="company">
                                  <BusinessIcon
                                    sx={{
                                      mr: 1,
                                      color: "gray",
                                      height: "14px",
                                    }}
                                  />
                                  {role.company}
                                </div>
                                <div className="location">
                                  <PlaceIcon
                                    sx={{
                                      mr: 1,
                                      color: "gray",
                                      height: "14px",
                                    }}
                                  />
                                  {role.location}
                                </div>
                                <div className="dates">
                                  {" "}
                                  <CalendarTodayIcon
                                    sx={{
                                      mr: 1,
                                      color: "gray",
                                      height: "14px",
                                    }}
                                  />
                                  {dayjs(role.start_date).format("MMM YYYY")} -{" "}
                                  {dayjs(role.end_date).format("MMM YYYY")}
                                </div>
                                <div
                                  className="description"
                                  dangerouslySetInnerHTML={{
                                    __html: role.description.replace(
                                      /\n/g,
                                      "<br>"
                                    ),
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="bubble">
                      <div className="resume">
                        <div className="header">
                          <div className="title">Licenses & Certifications</div>
                        </div>

                        {user &&
                          (!user.certifications ||
                            user.certifications.length < 1) && (
                            <div className="body">
                              No certifications or licenses to show
                            </div>
                          )}

                        {user &&
                          user.certifications &&
                          user.certifications.length > 0 &&
                          user.certifications.map((cert) => {
                            return (
                              <div key={cert.title} className="certifications">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <div className="role">{cert.title}</div>
                                </div>
                                <div className="issued-by">
                                  <BusinessIcon
                                    sx={{
                                      mr: 1,
                                      color: "gray",
                                      height: "14px",
                                    }}
                                  />
                                  {cert.issued_by}
                                </div>
                                <div className="valid-dates">
                                  {" "}
                                  <CalendarTodayIcon
                                    sx={{
                                      mr: 1,
                                      color: "gray",
                                      height: "14px",
                                    }}
                                  />
                                  {dayjs(cert.start_date).format("MMM YYYY")} -{" "}
                                  {dayjs(cert.end_date).format("MMM YYYY")}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="bubble">
                      <div className="resume">
                        <div className="header">
                          <div className="title">Portfolio</div>
                        </div>
                        <div className="portfolio">
                          {user &&
                            (!user.portfolio || user.portfolio.length < 1) && (
                              <div className="body">No images to display</div>
                            )}

                          {user &&
                            user.portfolio &&
                            user.portfolio.length > 0 &&
                            user.portfolio.map((image) => {
                              return (
                                <img
                                  key={image}
                                  className="portfolio-image"
                                  src={`https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${user.id}/${image}`}
                                />
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </Box>
                </Drawer>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Applicants;
