import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import dayjs from "dayjs";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { checkUserSignedIn } from "./AuthUtils";
import BusinessIcon from "@mui/icons-material/Business";

const JobView = () => {
  let navigate = useNavigate();
  const [openAppForm, setOpenAppForm] = useState(false);
  const [customNote, setCustomNote] = useState("");
  const [job, setJob] = useState(null);
  const { jobId } = useParams();
  const [userId, setUserId] = useState(null);
  const [applied, setApplied] = useState(false);
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleAppFormOpen = async () => {
    if (!signedIn) {
      navigate("/signin");
    }
    if (job.external_apply_url) {
      const { data, error } = await supabase
        .from("Applications")
        .insert([{ job_id: job.id, boosted: false, user_id: userId }])
        .select();

      if (error) {
        console.error("Error while applying:", error);
      } else {
        setApplied(true);
        window.open(job.external_apply_url, "_blank");
      }
    } else {
      setOpenAppForm(true);
    }
  };

  const handleAppFormClose = () => {
    setOpenAppForm(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await supabase
        .from("Jobs")
        .select()
        .eq("id", jobId);
      if (error) {
        console.error("Error fetching data:", error);
      } else {
        setJob(data[0]);
      }
    };

    fetchData();

    checkUserSignedIn().then(async ({ session, error }) => {
      if (error) {
        console.error("Error checking user sign-in status", error);
        // Handle error (e.g., show a message or redirect to a sign-in page)
      } else {
        if (!session) {
          setSignedIn(false);
        } else {
          setSignedIn(true);
          getUser();
        }
      }
    });
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  const applyToJob = async () => {
    const { data, error } = await supabase
      .from("Applications")
      .insert([
        { job_id: job.id, note: customNote, boosted: false, user_id: userId },
      ])
      .select();

    if (error) {
      console.error("Error while applying:", error);
    } else {
      setApplied(true);
      setOpenAppForm(false);
    }
  };

  const checkForApplication = async (id) => {
    // Check if an application exists
    const { data: applicationData, error: applicationError } = await supabase
      .from("Applications")
      .select()
      .eq("job_id", jobId) // Assuming the column that references Jobs.id is named job_id
      .eq("user_id", id);

    if (applicationError) {
      console.error(applicationError);
    } else {
      if (applicationData.length > 0) {
        setApplied(true);
      }
    }
  };

  const getUser = async () => {
    const { data, error } = await supabase.auth.getUser();
    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setUserId(data.user.id);
      checkForApplication(data.user.id);
    }
  };
  return (
    <Box
      sx={{
        height: "auto",
        paddingBottom: "50px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className="job-view-container"
        style={{
          alignItems: {
            xs: "center",
            sm: "center",
            md: "flex-start",
            lg: "flex-start",
          },
        }}
      >
        <div className="back-to-jobs-container">
          <IconButton onClick={handleGoBack}>
            <ArrowBackIcon sx={{ width: "30px", height: "30px" }} />
          </IconButton>
          <div className="back-to-jobs">Back to Jobs</div>
        </div>

        {job ? (
          <Box className="job-view">
            <div className="title">{job.job_name}</div>

            <div className="job-details-container">
              <div className="job-company-container">
                <BusinessIcon
                  sx={{ width: "16px", color: "gray" }}
                ></BusinessIcon>
                <div className="job-company">{job.company_name}</div>
              </div>
              <div className="job-location-container">
                <FmdGoodIcon
                  sx={{ width: "20px", color: "gray" }}
                ></FmdGoodIcon>
                <div className="job-location">
                  {job.job_location_city}, {job.job_location_state}
                </div>
              </div>
              <div className="job-date-container">
                <CalendarTodayIcon
                  sx={{ width: "16px", color: "gray", ml: "2px", mr: "2px" }}
                />
                <div className="job-date">
                  {dayjs(job.createdAt).format("M/D/YY")}
                </div>
              </div>
              <div className="job-type-container">
                {job.job_type.map((type) => {
                  return (
                    <div key={type} className="job-chip">
                      {type}
                    </div>
                  );
                })}
              </div>
            </div>
            {applied ? (
              <button className="applied-button" disabled>
                Applied
              </button>
            ) : (
              <button onClick={handleAppFormOpen}>Apply</button>
            )}

            <Dialog open={openAppForm} onClose={handleAppFormClose}>
              <DialogTitle sx={{ fontFamily: "Urbanist" }}>
                Apply to Role
              </DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ fontFamily: "Urbanist" }}>
                  Please add a custom note. Your profile will be shared once you
                  apply!
                </DialogContentText>
                <TextField
                  multiline
                  rows={6}
                  fullWidth
                  sx={{
                    marginTop: "20px",
                    fontFamily: "Urbanist",
                    "& .MuiOutlinedInput-root": {
                      // Default border color
                      "& fieldset": {
                        borderColor: "black",
                      },
                      // Hover border color
                      "&:hover fieldset": {
                        borderColor: "black",
                      },
                      // Focused border color
                      "&.Mui-focused fieldset": {
                        borderColor: "black",
                      },
                    },
                  }}
                  value={customNote}
                  onChange={(e) => setCustomNote(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                  onClick={handleAppFormClose}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                  onClick={applyToJob}
                >
                  Apply
                </Button>
              </DialogActions>
            </Dialog>
            <div className="description">Description</div>
            <div
              className="description-text"
              dangerouslySetInnerHTML={{
                __html: job.job_description,
              }}
            />
          </Box>
        ) : (
          <Box className="job-view">
            <Skeleton
              sx={{ height: "4rem", width: "20rem" }}
              variant="text"
            ></Skeleton>

            <div className="job-details-container">
              <Skeleton
                sx={{ height: "2rem", width: "30rem" }}
                variant="text"
              ></Skeleton>
            </div>
            <Skeleton
              sx={{ height: "1rem", width: "40rem" }}
              variant="text"
            ></Skeleton>
            <Skeleton
              sx={{ height: "1rem", width: "40rem" }}
              variant="text"
            ></Skeleton>
            <Skeleton
              sx={{ height: "1rem", width: "40rem" }}
              variant="text"
            ></Skeleton>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default JobView;
