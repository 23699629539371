import { supabase } from "./SupabaseClient";

/**
 * Checks if a user is currently signed in.
 *
 * @returns {Promise<{user: object | null, error: object | null}>}
 * A promise that resolves with an object containing the user information
 * if signed in, or null if not signed in, along with any error that occurred.
 */
export async function checkUserSignedIn() {
  const { data, error } = await supabase.auth.getSession();

  if (data) {
    return { session: data.session, error: null };
  } else {
    return { session: null, error };
  }
}
