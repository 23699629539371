import React from "react";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";

const AboutUs = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="aboutus">
      <div className="title fadeInAnimation">Our Story</div>
      <div className="content-wrapper fadeInAnimation">
        <div className="content">
          Our mission is to bridge the gap between aesthetic practices seeking
          talented professionals and individuals looking for rewarding career
          opportunities in this vibrant sector.
        </div>

        <div className="content">
          Aesthetics Portal is designed to simplify the hiring process, making
          it easier, faster, and more efficient for both employers and job
          seekers. Whether you're a clinic looking to expand your team or a
          professional seeking new challenges in aesthetics, our platform is
          tailored to meet your needs.
        </div>

        <div className="content">Contact us at hello@aestheticsportal.com</div>
      </div>
    </Box>
  );
};

export default AboutUs;
