import "./App.css";
import TopAppBar from "./components/TopAppBar";
import Home from "./components/Home";
import AuthComponent from "./components/Auth";
import JobView from "./components/JobView";
import { Routes, Route, useLocation } from "react-router-dom";
import Pricing from "./components/Pricing";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import CheckoutForm from "./components/CheckoutForm";
import UpdatePassword from "./components/UpdatePassword";
import AboutUs from "./components/AboutUs";

function App() {
  const location = useLocation();
  const isSignInPage = location.pathname === "/signin";
  const isUpdatePasswordPage = location.pathname === "/update-password";
  return (
    <div className="App">
      {isSignInPage || isUpdatePasswordPage ? null : <TopAppBar />}
      <Routes>
        <Route path="/signin" element={<AuthComponent />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route path="/" element={<Home />} />
        <Route path="/job/:jobId" element={<JobView />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/profile/:profileId" element={<Profile />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/checkout" element={<CheckoutForm />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>
    </div>
  );
}

export default App;
