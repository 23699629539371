import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { supabase } from "./SupabaseClient";
import dayjs from "dayjs";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BusinessIcon from "@mui/icons-material/Business";
import { v4 as uuidv4 } from "uuid";

import { checkUserSignedIn } from "./AuthUtils";

const filterMapping = {
  filter1: "Full Time",
  filter2: "Part Time",
  filter3: "Internship",
  filter4: "Contract",
};

const Home = () => {
  const navigate = useNavigate();
  const [checkedFilters, setCheckedFilters] = useState({});
  // State to manage expanded accordions
  const [expanded, setExpanded] = useState(["panel1", "panel2"]); // Initialize with all panel IDs you have
  const [jobs, setJobs] = useState([]);
  const [searchParams, setSearchParams] = useState();
  const [locationParams, setLocationParams] = useState();
  const [noJobsFound, setNoJobsFound] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState(jobs);
  const [sort, setSort] = useState("relevance");
  const [favorites, setFavorites] = useState({});
  const [userId, setUserId] = useState(null);
  const [signedIn, setSignedIn] = useState(false);
  const [promotedJobs, setPromotedJobs] = useState([]);
  const [normalJobs, setNormalJobs] = useState([]);
  const [jobsToShow, setJobsToShow] = useState([]);

  useEffect(() => {
    setJobsToShow(interleaveJobsWithPattern());
  }, [promotedJobs, normalJobs]);

  useEffect(() => {
    const promoted = filteredJobs.filter((job) => job.promoted);
    const normal = filteredJobs.filter((job) => !job.promoted);
    setPromotedJobs(promoted);
    setNormalJobs(normal);
  }, [filteredJobs]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  const interleaveJobsWithPattern = () => {
    // Shuffle the promoted jobs to randomize their order
    const shuffledPromotedJobs = shuffleArray([...promotedJobs]);

    let interleavedJobs = [];
    let promotedIndex = 0,
      normalIndex = 0; // Use normalIndex for clarity
    let totalSlots = promotedJobs.length * 3 + normalJobs.length; // Calculate total slots needed

    for (let i = 0; i < totalSlots; i++) {
      // Calculate the position in the current cycle of 12 (3 promoted + 9 regular)
      let positionInCycle = i % 12;

      // Insert 3 promoted jobs at the start of each cycle if available
      if (positionInCycle < 3 && promotedIndex < shuffledPromotedJobs.length) {
        interleavedJobs.push(shuffledPromotedJobs[promotedIndex++]);
      } else if (normalIndex < normalJobs.length) {
        // Then fill with normal (non-promoted) jobs
        interleavedJobs.push(normalJobs[normalIndex++]);
      }
    }

    return interleavedJobs;
  };

  useEffect(() => {
    checkUserSignedIn().then(async ({ session, error }) => {
      if (error) {
        console.error("Error checking user sign-in status", error);
        // Handle error (e.g., show a message or redirect to a sign-in page)
      } else {
        if (!session) {
          setSignedIn(false);
        } else {
          setSignedIn(true);
          setUserId(session.user.id);
          const { data, error } = await supabase
            .from("Users")
            .select("favorites")
            .eq("id", session.user.id);

          if (error) {
            console.error("Error fetching user data:", error);
          } else {
            setFavorites(data[0].favorites);
          }
        }
        getAllJobs();
      }
    });
  }, []);

  const getAllJobs = async () => {
    const { data, error } = await supabase
      .from("Jobs")
      .select()
      .order("promoted", { ascending: false });
    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setNoJobsFound(false);
      setSearchParams("");
      setLocationParams("");
      setJobs(data);
    }
  };

  const updateFavoritesInSupabase = async (newFavorites) => {
    // Assuming you have the user's ID stored in a variable `userId`
    const { data, error } = await supabase
      .from("Users")
      .update({ favorites: newFavorites })
      .eq("id", userId); // Make sure 'id' matches the primary key column name in your Users table

    if (error) {
      throw new Error("Error updating favorites in Supabase:", error.message);
    }
    return data;
  };

  const addToFavorites = (id) => {
    if (!signedIn) {
      navigate("/signin");
    }
    setFavorites((prevFavorites) => {
      // Check if the ID is already in the favorites
      if (!prevFavorites.hasOwnProperty(id)) {
        const newFavorites = { ...prevFavorites, [id]: true }; // Prepare the new favorites object

        // Update Supabase after state update
        updateFavoritesInSupabase(newFavorites).catch((error) => {
          console.error("Error updating favorites in Supabase:", error);
        });

        return newFavorites;
      } else {
        // If the ID exists, log a message and return the unchanged favorites
        return prevFavorites;
      }
    });
  };

  const removeFromFavorites = (id) => {
    setFavorites((prevFavorites) => {
      if (prevFavorites.hasOwnProperty(id)) {
        // Create a new object without the specified id
        const { [id]: value, ...newFavorites } = prevFavorites;
        // Update Supabase after state update
        updateFavoritesInSupabase(newFavorites).catch((error) => {
          console.error("Error updating favorites in Supabase:", error);
        });
        return newFavorites;
      } else {
        return prevFavorites;
      }
    });
  };

  const handleSortChange = (event) => {
    const value = event.target.value;
    setSort(value); // Update the sort state

    let sortedJobs = [];
    if (value === "most recent") {
      // Sort jobs by created_at date, newest first
      sortedJobs = [...filteredJobs].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    } else if (value === "most popular") {
      // Sort jobs by app_count, highest first
      sortedJobs = [...filteredJobs].sort((a, b) => b.app_count - a.app_count);
    } else {
      // 'relevance' or default case, return jobs as is or apply any default sorting
      sortedJobs = [...jobs];
    }

    setFilteredJobs(sortedJobs); // Update the filteredJobs state with the sorted jobs
  };

  const navigateToJobPage = (id) => {
    navigate(`/job/${id}`);
  };

  const handleFilterChange = (event) => {
    setCheckedFilters({
      ...checkedFilters,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded((prev) => [...prev, panel]);
    } else {
      setExpanded((prev) => prev.filter((p) => p !== panel));
    }
  };

  const handleEnterPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (searchParams || locationParams) {
        handleJobSearch();
      }
    }
  };

  const handleJobSearch = async () => {
    // Start building the query
    let query = supabase.from("Jobs").select("*");

    // Conditionally add the ilike filter for job_name if searchParams is truthy
    if (searchParams) {
      query = query.ilike("job_name", `%${searchParams}%`);
    }

    // Conditionally add the or filter for location if locationParams is truthy
    if (locationParams) {
      const locationFilter = `job_location_city.ilike.%${locationParams}%,job_location_state.ilike.%${locationParams}%`;
      query = query.or(locationFilter);
    }

    // Execute the query
    let { data, error } = await query;

    // Handle the response
    if (error) {
      console.error("Error fetching data:", error);
    } else {
      if (data.length > 0) {
        setNoJobsFound(false);
        setJobs(data);
      } else {
        setNoJobsFound(true);
      }
    }
  };

  const filterJobsByType = (jobs, checkedFilters) => {
    // Convert checked filters into an array of selected employment types
    const selectedTypes = Object.entries(checkedFilters)
      .filter(([key, value]) => value) // Keep only checked filters
      .map(([key]) => filterMapping[key]); // Convert filter keys to employment types

    // If no filters are selected, return all jobs
    if (selectedTypes.length === 0) {
      return jobs;
    }

    // Filter jobs based on the selected employment types
    const filteredJobs = jobs.filter((job) =>
      job.job_type.some((type) => selectedTypes.includes(type))
    );

    return filteredJobs;
  };

  useEffect(() => {
    const updatedFilteredJobs = filterJobsByType(jobs, checkedFilters);
    setFilteredJobs(updatedFilteredJobs);
  }, [jobs, checkedFilters]); // Depend on jobs and checkedFilters

  return (
    <Box sx={{ height: "100%" }}>
      <Box className="home-top">
        <div className="home-header">Find Your Next Job in Aesthetics</div>
        <div className="search-bar">
          <input
            onKeyDown={handleEnterPress}
            value={searchParams || ""}
            onChange={(e) => setSearchParams(e.target.value)}
            type="text"
            placeholder="Job title or company"
          />
          <Divider
            sx={{ borderWidth: { xs: "0.1px", sm: "0.1px" } }}
            orientation="vertical"
            variant="middle"
            flexItem
          />

          <input
            onKeyDown={handleEnterPress}
            value={locationParams || ""}
            onChange={(e) => setLocationParams(e.target.value)}
            type="text"
            placeholder="City or state"
          />
          <button onClick={handleJobSearch} type="submit">
            Find Jobs
          </button>
        </div>
      </Box>
      <Box
        className="home-jobs"
        sx={{
          bgcolor: {
            xs: "#ffffff",
            sm: "#ffffff",
            md: "#fafbfc",
            lg: "#fafbfc",
          },
        }}
      >
        <Box className="jobs-outer-container">
          <Box className="filters-container">
            <Accordion
              expanded={expanded.includes("panel1")}
              onChange={handleAccordionChange("panel1")}
              className="new-accordion"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="filter-title">
                  Employment Type
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "200px",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFilters["filter1"] || false}
                      onChange={handleFilterChange}
                      name="filter1"
                      sx={{
                        "&.Mui-checked": {
                          color: "#000000", // Custom color when checked
                        },
                      }}
                    />
                  }
                  label={
                    <Typography className="filter-options">
                      Full Time
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFilters["filter2"] || false}
                      onChange={handleFilterChange}
                      name="filter2"
                      sx={{
                        "&.Mui-checked": {
                          color: "#000000", // Custom color when checked
                        },
                      }}
                    />
                  }
                  label={
                    <Typography className="filter-options">
                      Part Time
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFilters["filter3"] || false}
                      onChange={handleFilterChange}
                      name="filter3"
                      sx={{
                        "&.Mui-checked": {
                          color: "#000000", // Custom color when checked
                        },
                      }}
                    />
                  }
                  label={
                    <Typography className="filter-options">
                      Internship
                    </Typography>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedFilters["filter4"] || false}
                      onChange={handleFilterChange}
                      name="filter4"
                      sx={{
                        "&.Mui-checked": {
                          color: "#000000", // Custom color when checked
                        },
                      }}
                    />
                  }
                  label={
                    <Typography className="filter-options">Contract</Typography>
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyItems: "flexStart",
              width: "100%",
            }}
          >
            {!noJobsFound && filteredJobs && filteredJobs.length > 0 && (
              <div className="header">
                <div className="job-count">
                  Showing {filteredJobs.length} jobs
                </div>
                <Select
                  sx={{
                    fontFamily: "Urbanist",
                    fontSize: "14px",
                    minWidth: "150px",
                    ".MuiSelect-select": {
                      padding: "5px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black", // Default border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black", // Hover border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "black", // Focused border color
                    },
                  }}
                  value={sort}
                  onChange={handleSortChange}
                >
                  <MenuItem
                    sx={{
                      fontFamily: "Urbanist",
                      fontSize: "14px",
                    }}
                    value={"relevance"}
                  >
                    Relevance
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Urbanist",
                      fontSize: "14px",
                    }}
                    value={"most recent"}
                  >
                    Most Recent
                  </MenuItem>
                  <MenuItem
                    sx={{
                      fontFamily: "Urbanist",
                      fontSize: "14px",
                    }}
                    value={"most popular"}
                  >
                    Most Popular
                  </MenuItem>
                </Select>
              </div>
            )}
            <Box className="jobs-container">
              {noJobsFound && (
                <div className="no-jobs-wrapper">
                  <div className="no-jobs-found">
                    No jobs found. Please try a different search.
                  </div>
                  <button onClick={getAllJobs}>View All Jobs</button>
                </div>
              )}

              {!noJobsFound &&
                jobsToShow &&
                jobsToShow.length > 0 &&
                jobsToShow.map((job) => {
                  return (
                    <div className="job" key={uuidv4()}>
                      <div>
                        <div className="job-name">{job.job_name}</div>
                        <div className="job-company-container">
                          <BusinessIcon
                            sx={{ width: "16px", color: "gray" }}
                          ></BusinessIcon>
                          <div className="job-company">{job.company_name}</div>
                        </div>
                        <div className="job-location-container">
                          <FmdGoodIcon
                            sx={{ width: "16px", color: "gray" }}
                          ></FmdGoodIcon>
                          <div className="job-location">
                            {job.job_location_city}, {job.job_location_state}
                          </div>
                        </div>
                        <div className="job-date-container">
                          <CalendarTodayIcon
                            sx={{ width: "16px", color: "gray" }}
                          />
                          <div className="job-date">
                            {dayjs(job.created_at).format("M/D/YY")}
                          </div>
                        </div>

                        <div className="job-type-container">
                          {job.job_type.map((type) => {
                            return (
                              <div key={type} className="job-chip">
                                {type}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="job-footer">
                        {favorites && favorites[job.id] ? (
                          <FavoriteIcon
                            sx={{ color: "#ff7e7ed1" }}
                            onClick={() => removeFromFavorites(job.id)}
                          />
                        ) : (
                          <FavoriteBorderIcon
                            className="favorite-icon"
                            onClick={() => addToFavorites(job.id)}
                          />
                        )}
                        {job.promoted && (
                          <div className="promoted">Promoted</div>
                        )}

                        <button
                          className="jobs-btn"
                          onClick={() => navigateToJobPage(job.id)}
                        >
                          Details
                        </button>
                      </div>
                    </div>
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
