import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Sortable from "sortablejs";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const ImageItem = React.memo(
  ({ id, content, isSelected, onToggle, isFirstChild, type }) => {
    return (
      <div
        key={id}
        data-id={id}
        draggable
        className={`image-wrapper ${
          isFirstChild ? "first-item" : "other-item"
        } ${isSelected ? "checked" : ""}`}
        onClick={() => onToggle(id)}
      >
        <img src={content} className="image-item" alt={`content-${id}`} />

        <div className="checkbox-container">
          <Checkbox
            checked={isSelected}
            sx={{
              color: "#ffffff",
              "&.Mui-checked": {
                color: "#000000",
              },
            }}
            size="small"
          />
        </div>
      </div>
    );
  }
);

const SortableTool = ({ sendImages, initialData = [] }) => {
  const gridRef = useRef(null);
  // State to track selected items for deletion
  const [selected, setSelected] = useState(new Set());
  const [data, setData] = useState([]);

  // Function to update the state with the new order
  const updateState = useCallback(
    (newOrder) => {
      const newData = newOrder.map((id) => data.find((item) => item.id === id));
      setData(newData);
    },
    [data]
  );

  useEffect(() => {
    sendImages(data);
  }, [data, sendImages]);

  const handleSelectChange = (id) => {
    setSelected((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  // Function to delete selected items
  const deleteSelectedItems = async () => {
    const selectedForDeletion = data.filter(
      (item) => selected.has(item.id) && !item.isNew
    );

    setData((currentData) =>
      currentData.filter((item) => !selected.has(item.id))
    );

    // Reset the selected items
    setSelected(new Set());
  };

  useEffect(() => {
    const sortableJsRef = Sortable.create(gridRef.current, {
      onEnd: (evt) => {
        const { oldIndex, newIndex } = evt;
        if (oldIndex !== newIndex) {
          const newOrder = sortableJsRef.toArray();
          updateState(newOrder);
          sessionStorage.setItem("my-grid", JSON.stringify(newOrder));
        }
      },
    });

    // Cleanup function to prevent memory leaks
    return () => {
      sortableJsRef.destroy();
    };
  }, [data, updateState]); // Re-run effect if data changes

  // Function to handle file drop
  const onDrop = useCallback((acceptedFiles) => {
    // Create new items from the accepted files and update the data state
    const newItems = acceptedFiles.map((file, index) => ({
      id: `new_${Date.now()}_${index}`, // Generate a unique ID for each new item
      content: URL.createObjectURL(file), // Create a URL for the dropped file
    }));

    setData((currentData) => [...currentData, ...newItems]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    multiple: true,
  });

  const toggleSelection = (id) => {
    handleSelectChange(id);
  };

  useEffect(() => {
    // Populate the data state with initialData if provided
    if (initialData.length > 0) {
      const initialDataFormatted = initialData.map((url, index) => ({
        id: `existing_${index}`,
        content: url,
        isNew: false, // Flag to indicate this is an existing image
      }));
      setData(initialDataFormatted);
    }
  }, [initialData]);

  return (
    <div style={{ width: "100%", marginTop: "20px" }}>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <p>Drag and drop images here, or click to select images</p>
      </div>

      <Button
        sx={{
          marginTop: "20px",
          backgroundColor: "#000000",
          ":hover": {
            bgcolor: "#000000d1",
          },
        }}
        size="small"
        variant="contained"
        onClick={deleteSelectedItems}
        disabled={selected.size === 0}
      >
        Delete Selected
      </Button>

      <div ref={gridRef} className="grid-container">
        {data.map(({ id, content, type }, index) => (
          <ImageItem
            key={id}
            id={id}
            content={content}
            isSelected={selected.has(id)}
            onToggle={toggleSelection}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

export default SortableTool;
