import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { supabase } from "./SupabaseClient";
import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import dayjs from "dayjs";
import SendIcon from "@mui/icons-material/Send";
import Badge from "@mui/material/Badge";

const Messages = ({ user }) => {
  const navigate = useNavigate();
  const [threads, setThreads] = useState([]);
  const [activeThread, setActiveThread] = useState(null);
  const [progress, setProgress] = useState(true);
  const [messages, setMessages] = useState([]);
  const [messageProgress, setMessageProgress] = useState(false);
  const [currentProfilePicture, setCurrentProfilePicture] = useState(null);
  const [currentName, setCurrentName] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [messageText, setMessageText] = useState("");

  const handleEnterPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (messageText) {
        sendMessage();
      }
    }
  };

  const getMessages = async (id) => {
    setMessageProgress(true);
    const { data, error } = await supabase
      .from("Messages")
      .select(
        `*,
    owner:Users!owner_id (id, first_name, last_name, profile_picture)`
      )
      .eq("thread_id", id);
    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setMessageProgress(false);
      setMessages(data);
    }
  };

  const sendMessage = async () => {
    const item = {
      thread_id: activeThread,
      message: messageText,
      owner_id: user.id,
    };
    const { data, error } = await supabase.from("Messages").insert([item]);
    if (error) {
      console.error("Error sending message:", error);
    } else {
      setMessageText("");
      setMessages((prevMessages) => [...prevMessages, item]);
    }
  };

  const getThreads = async (id) => {
    const { data, error } = await supabase
      .from("Threads")
      .select(
        `
    *,
    sender:Users!sender_id (id, first_name, last_name, profile_picture),
    recipient:Users!recipient_id (id, first_name, last_name, profile_picture),
    unread:Threads_Unread!inner(thread_id, unread)
  `
      )
      .eq("Threads_Unread.user_id", user.id)
      .or(`sender_id.eq.${user.id},recipient_id.eq.${user.id}`)
      .order("updated_at", { ascending: false });

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setProgress(false);
      setThreads(data);
      handleThread(data[0]);
    }
  };

  useEffect(() => {
    if (user) {
      getThreads(user.id);
    }
  }, []);

  const handleThread = async (thread) => {
    setActiveThread(thread.id);
    getMessages(thread.id);
    if (thread.sender_id === user.id) {
      setCurrentProfilePicture(thread.recipient.profile_picture);
      setCurrentName(
        `${thread.recipient.first_name} ${thread.recipient.last_name}`
      );
      setCurrentId(thread.recipient.id);
    } else {
      setCurrentProfilePicture(thread.sender.profile_picture);
      setCurrentName(`${thread.sender.first_name} ${thread.sender.last_name}`);
      setCurrentId(thread.sender.id);
    }

    const { data, error } = await supabase
      .from("Threads_Unread")
      .update({ unread: 0 })
      .match({ thread_id: thread.id, user_id: user.id });
  };

  const handleTime = (time) => {
    // Calculate the difference from now
    const now = dayjs();
    const differenceInMinutes = now.diff(dayjs(time), "minute");
    const differenceInHours = now.diff(dayjs(time), "hour");
    const differenceInDays = now.diff(dayjs(time), "day");

    let output;

    if (differenceInMinutes < 60) {
      // Less than 1 hour
      output = `${differenceInMinutes}m`;
    } else if (differenceInHours < 24) {
      // More than 59 minutes but less than 24 hours
      output = `${differenceInHours}h`;
    } else {
      // More than 24 hours
      output = `${differenceInDays}d`;
    }
    return output;
  };

  return (
    <div className="messaging">
      <div className="msg-sidebar">
        {/* <div className="header">Messages</div> */}
        {threads &&
          threads.length > 0 &&
          threads.map((thread) => (
            <div
              key={thread.id}
              style={{
                backgroundColor:
                  activeThread === thread.id ? "#eeeef8" : "#ffffff",
              }}
              className="thread"
              onClick={() => handleThread(thread)}
            >
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Badge
                    badgeContent={thread.unread[0].unread}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#000000",
                        color: "white",
                      }, // Customizing badge background and text color
                    }}
                  >
                    <Avatar
                      className="avatar-chat"
                      src={
                        thread.sender.id === user.id
                          ? `https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${thread.recipient.id}/${thread.recipient.profile_picture}`
                          : `https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${thread.sender.id}/${thread.sender.profile_picture}`
                      }
                    />
                  </Badge>
                </Grid>
                <Grid item xs={7}>
                  {thread.sender.id === user.id ? (
                    <Grid item xs={12} className="name">
                      {thread.recipient.first_name} {thread.recipient.last_name}
                    </Grid>
                  ) : (
                    <Grid item xs={12} className="name">
                      {thread.sender.first_name} {thread.sender.last_name}
                    </Grid>
                  )}

                  <Grid item xs={12} className="message">
                    {thread.last_message}
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item xs={12} className="time">
                    {handleTime(thread.updated_at)}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        {progress && (
          <React.Fragment>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Skeleton variant="circular" width={40} height={40} />
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12} className="name">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
                <Grid item xs={12} className="message">
                  <Skeleton variant="text" sx={{ fontSize: "12px" }} />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid item xs={12} className="time">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Skeleton variant="circular" width={40} height={40} />
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12} className="name">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
                <Grid item xs={12} className="message">
                  <Skeleton variant="text" sx={{ fontSize: "12px" }} />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid item xs={12} className="time">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Skeleton variant="circular" width={40} height={40} />
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12} className="name">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
                <Grid item xs={12} className="message">
                  <Skeleton variant="text" sx={{ fontSize: "12px" }} />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid item xs={12} className="time">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Skeleton variant="circular" width={40} height={40} />
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12} className="name">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
                <Grid item xs={12} className="message">
                  <Skeleton variant="text" sx={{ fontSize: "12px" }} />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid item xs={12} className="time">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Skeleton variant="circular" width={40} height={40} />
              </Grid>
              <Grid item xs={7}>
                <Grid item xs={12} className="name">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
                <Grid item xs={12} className="message">
                  <Skeleton variant="text" sx={{ fontSize: "12px" }} />
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid item xs={12} className="time">
                  <Skeleton variant="text" sx={{ fontSize: "16px" }} />
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
      <div className="messages-container">
        <div>
          {" "}
          <div className="header">
            <Avatar
              sx={{
                width: "50px",
                height: "50px",
              }}
              src={`https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${currentId}/${currentProfilePicture}`}
            />
            <div>{currentName}</div>
          </div>
          <div className="body">
            {messages &&
              messages.length > 0 &&
              messages.map((message) => (
                <div
                  className={`message-bubble ${
                    message.owner_id === user.id ? "right" : "left"
                  }`}
                  key={message.id}
                >
                  <div className="message-text">{message.message}</div>
                  <div className="message-time">
                    {handleTime(message.created_at)} ago
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="messages-footer">
          <input
            onKeyDown={handleEnterPress}
            value={messageText || ""}
            onChange={(e) => setMessageText(e.target.value)}
            type="text"
            placeholder="Your message"
          />
          <SendIcon onClick={sendMessage} />
        </div>
      </div>
    </div>
  );
};

export default Messages;
