import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CircularProgress from "@mui/material/CircularProgress";

export default function CheckoutForm({
  createListing,
  saveJob,
  upgradeToPremium,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't loaded yet.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url: "http://localhost:3000/dashboard",
      },
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsLoading(false);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      // Assuming the payment succeeded, call your Supabase function here
      try {
        if (createListing) {
          await createListing();
        }

        if (saveJob) {
          await saveJob();
        }

        if (upgradeToPremium) {
          await upgradeToPremium();
        }
        // After successful Supabase function call, redirect to your dashboard
      } catch (supabaseError) {
        console.error("Call failed:", supabaseError);
        setMessage("Failed to complete post-payment steps.");
        setIsLoading(false);
      }
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div className="checkout-form-wrapper">
      <form className="checkout-form" id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div
          style={{
            display: "flex",
            gap: "30px",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <button
            className="pay-button"
            disabled={isLoading || !stripe || !elements}
            id="submit"
          >
            Pay now
          </button>
          {isLoading && <CircularProgress sx={{ color: "grey" }} />}
        </div>
      </form>
    </div>
  );
}
