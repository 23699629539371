import React from "react";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import { supabase } from "./SupabaseClient";
import { useParams } from "react-router-dom";
import { checkUserSignedIn } from "./AuthUtils";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { resizeAndCompressImage } from "./imageUtils";
import { styled } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";
import SortableTool from "./Sortable";
import InstagramIcon from "@mui/icons-material/Instagram";

// Create a custom theme
const theme = createTheme({
  components: {
    Typography: {
      styleOverrides: {
        root: {
          fontFamily: "Urbanist", // Your desired fontFamily
          fontSize: "14px", // Your desired fontSize
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "Urbanist", // Your desired fontFamily
          fontSize: "14px", // Your desired fontSize
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Target the root and change the border color
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black", // Change to your desired color
          },
        },
      },
    },
  },
});

const Profile = () => {
  const { profileId } = useParams();
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [photoBlob, setPhotoBlob] = useState(null);
  const [upload, setUpload] = useState(false);
  const [photoName, setPhotoName] = useState("");
  const [editAboutMe, setEditAboutMe] = useState(false);
  const [aboutMe, setAboutMe] = useState("");
  const [editExperience, setEditExperience] = useState(false);
  const [experience, setExperience] = useState([]);
  const [editCertifications, setEditCertifications] = useState(false);
  const [certifications, setCertifications] = useState([]);
  const [editPortfolio, setEditPortfolio] = useState(false);
  const [portfolio, setPortfolio] = useState([]);
  const [experienceModal, setExperienceModal] = useState(false);
  const [currentJobTitle, setCurrentJobTitle] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [currentStartDate, setCurrentStartDate] = useState(null);
  const [currentEndDate, setCurrentEndDate] = useState(null);
  const [currentDescription, setCurrentDescription] = useState("");
  const [currentRole, setCurrentRole] = useState(false);
  const [activateDeletion, setActivateDeletion] = useState(false);
  const [certificationsModal, setCertificationsModal] = useState(false);
  const [activateCertDeletion, setActivateCertDeletion] = useState(false);
  const [currentCertTitle, setCurrentCertTitle] = useState("");
  const [currentIssuedBy, setCurrentIssuedBy] = useState("");
  const [currentCertDateStart, setCurrentCertDateStart] = useState(null);
  const [currentCertDateEnd, setCurrentCertDateEnd] = useState(null);
  const [images, setImages] = useState([]);
  const [instagram, setInstagram] = useState("");

  // Handle change event for the checkbox
  const handleRoleChange = (event) => {
    // Update the state based on the checkbox's checked status
    setCurrentRole(event.target.checked);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    const getUser = async () => {
      const { data, error } = await supabase
        .from("Users")
        .select()
        .eq("id", profileId);
      if (error) {
        console.error("Error fetching data:", error);
      } else {
        setUser(data[0]);
        if (data[0].first_name) {
          setFirstName(data[0].first_name);
        }
        if (data[0].about_me) {
          setAboutMe(data[0].about_me);
        }
        if (data[0].last_name) {
          setLastName(data[0].last_name);
        }
        if (data[0].experience) {
          setExperience(data[0].experience);
        }
        if (data[0].certifications) {
          setCertifications(data[0].certifications);
        }
        if (data[0].portfolio) {
          setImages(data[0].portfolio);
        }
        if (data[0].location_city) {
          setCity(data[0].location_city);
        }
        if (data[0].location_state) {
          setState(data[0].location_state);
        }
        if (data[0].instagram) {
          setInstagram(data[0].instagram);
        }
        if (data[0].profile_picture) {
          setProfilePhoto(
            `https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${profileId}/${data[0].profile_picture}`
          );
        }
      }
    };

    getUser();

    checkUserSignedIn().then(({ session, error }) => {
      if (error) {
        console.error("Error checking user sign-in status", error);
        // Handle error (e.g., show a message or redirect to a sign-in page)
      } else {
        if (session.user.id === profileId) {
          setEditMode(true);
        }
      }
    });
  }, []);

  const handleEditExperience = () => {
    setEditExperience(true);
  };

  const closeEditExperience = () => {
    setEditExperience(false);
    if (user.experience) {
      setExperience(user.experience);
    } else {
      setExperience([]);
    }
  };

  const openExperienceModal = () => {
    setExperienceModal(true);
  };

  const closeExperienceModal = () => {
    setExperienceModal(false);
  };

  const uploadProfilePhoto = async () => {
    const response = await fetch(photoBlob.content);
    const blob = await response.blob();

    const resizedBlob = await resizeAndCompressImage(
      blob,
      2048, // Width
      2048, // Height
      0.9, // Quality (slightly reduced for better file size management)
      true // Convert to WebP
    );

    // Change the file extension to .webp if converting to WebP
    const fileName = photoName.replace(/\.\w+$/, "") + ".webp";

    const file = new File([resizedBlob], fileName, { type: "image/webp" });
    const { data, error } = await supabase.storage
      .from("media")
      .upload(`/${profileId}/${fileName}`, file);
    if (error) {
      console.error("Error uploading file:", error);
    } else {
      setUpload(false);
      const { data, error } = await supabase
        .from("Users")
        .update({ profile_picture: fileName }) // Use the dynamically constructed payload
        .eq("id", profileId)
        .select();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveExperience = async () => {
    const { data, error } = await supabase
      .from("Users")
      .update({ experience: experience }) // Use the dynamically constructed payload
      .eq("id", profileId)
      .select();
    if (error) {
      console.error("Error while saving experience:", error);
    }
    setEditExperience(false);
  };

  const saveProfileDialog = async () => {
    const updatePayload = {};

    // Add fields to the payload only if they have a truthy value
    if (firstName) updatePayload.first_name = firstName;
    if (lastName) updatePayload.last_name = lastName;
    if (city) updatePayload.location_city = city;
    if (state) updatePayload.location_state = state;
    if (instagram) updatePayload.instagram = instagram;

    const { data, error } = await supabase
      .from("Users")
      .update(updatePayload) // Use the dynamically constructed payload
      .eq("id", profileId)
      .select();

    if (error) {
      console.error("Error while applying:", error);
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        first_name: firstName,
        last_name: lastName,
        location_city: city,
        location_state: state,
        instagram: instagram,
      }));
    }
    setOpen(false);
  };

  const openAboutMe = () => {
    setEditAboutMe(true);
  };

  const handleImages = (data) => {
    setImages(data);
  };

  const closeAboutMe = () => {
    setEditAboutMe(false);
    if (user.about_me) {
      setAboutMe(user.about_me);
    } else {
      setAboutMe("");
    }
  };

  const saveAboutMe = async () => {
    const { data, error } = await supabase
      .from("Users")
      .update({ about_me: aboutMe }) // Use the dynamically constructed payload
      .eq("id", profileId)
      .select();

    if (error) {
      console.error("Error while saving about me:", error);
    }
    setEditAboutMe(false);
  };

  const openExperience = () => {
    setEditExperience(true);
  };

  const closeExperience = () => {
    setEditExperience(false);
  };

  const openCertifications = () => {
    setEditCertifications(true);
  };

  const closeCertifications = () => {
    setEditCertifications(false);
  };

  const openPortfolio = () => {
    setEditPortfolio(true);
  };

  const closePortfolio = () => {
    setEditPortfolio(false);
  };

  const handleCertStartDateChange = (newValue) => {
    setCurrentCertDateStart(newValue);
  };

  const handleCertEndDateChange = (newValue) => {
    setCurrentCertDateEnd(newValue);
  };

  const handleEditCertifications = () => {
    setEditCertifications(true);
  };

  const saveCertifications = async () => {
    const { data, error } = await supabase
      .from("Users")
      .update({ certifications: certifications }) // Use the dynamically constructed payload
      .eq("id", profileId)
      .select();
    if (error) {
      console.error("Error while saving certifications:", error);
    }
    setEditCertifications(false);
  };

  const closeEditCertifications = () => {
    setEditCertifications(false);
    if (user.certifications) {
      setCertifications(user.certifications);
    } else {
      setCertifications([]);
    }
  };

  const openCertificationsModal = () => {
    setCertificationsModal(true);
  };

  const closeCertificationsModal = () => {
    setCertificationsModal(false);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const resizedBlob = await resizeAndCompressImage(
      file,
      2048, // Width
      2048, // Height
      0.9, // Quality (slightly reduced for better file size management)
      true
    ); // maxWidth, maxHeight, quality
    const localUrl = URL.createObjectURL(resizedBlob);
    //setAvatarSrc(localUrl);
    const imageObject = {
      content: URL.createObjectURL(file), // This is the file content
      id: file.name, // Unique identifier for the file
    };

    // Update your state with this new object
    setProfilePhoto(localUrl);
    setUpload(true);
    setPhotoName(file.name);
    setPhotoBlob(imageObject);
  };

  const savePortfolio = async () => {
    // Function to handle the upload of a single file
    const uploadFile = async (file) => {
      const response = await fetch(file.content);
      const blob = await response.blob();
      // Optionally, resize and compress each image here as needed
      const resizedBlob = await resizeAndCompressImage(
        blob,
        2048, // Width
        2048, // Height
        0.9, // Quality
        true // Convert to WebP, if that's part of your workflow
      );

      // Change the file extension to .webp if converting to WebP
      const fileName = file.id.replace(/\.\w+$/, "") + ".webp";
      const fileToUpload = new File([resizedBlob], fileName, {
        type: "image/webp",
      });

      // Upload the file
      const { error } = await supabase.storage
        .from("media")
        .upload(`${profileId}/${fileName}`, fileToUpload);

      if (error) {
        console.error("Error uploading file:", error);
        throw new Error(`Failed to upload ${fileName}`);
      }

      return fileName; // Return the file name if the upload is successful
    };

    // Use Promise.allSettled to attempt to upload all files concurrently
    const uploadPromises = images.map((file) => uploadFile(file));
    const results = await Promise.allSettled(uploadPromises);

    // Filter out successfully uploaded files
    const fileNames = results
      .filter((result) => result.status === "fulfilled")
      .map((result) => result.value); // Assuming the fulfilled promise returns the fileName

    // After all files are attempted to be uploaded, update the Users table
    if (fileNames.length > 0) {
      const { error: updateError } = await supabase
        .from("Users")
        .update({ portfolio: fileNames }) // Save the comma-separated string of file names
        .eq("id", profileId);

      if (updateError) {
        console.error("Error while saving portfolio:", updateError);
      } else {
        setEditPortfolio(false);
      }
    }
  };

  const addNewCert = async () => {
    const newCert = {
      title: currentCertTitle,
      issued_by: currentIssuedBy,
      start_date: currentCertDateStart ? currentCertDateStart.$d : null,
      end_date: currentCertDateEnd ? currentCertDateEnd.$d : null,
    };
    setCertifications([newCert, ...certifications]);
    setCertificationsModal(false);
  };

  const addNewRole = async () => {
    const newRole = {
      title: currentJobTitle,
      company: currentCompany,
      location: currentLocation,
      current_role: currentRole,
      start_date: currentStartDate ? currentStartDate.$d : null,
      end_date: currentEndDate ? currentEndDate.$d : null,
      description: currentDescription,
    };

    setExperience([newRole, ...experience]);

    setExperienceModal(false);
  };

  const handleStartDateChange = (newValue) => {
    setCurrentStartDate(newValue);
  };

  const handleEndDateChange = (newValue) => {
    setCurrentEndDate(newValue.$d);
  };

  const goToInstagram = () => {
    window.open(user.instagram, "_blank");
  };

  const handleEditRole = (role) => {
    setCurrentJobTitle(role.title);
    setCurrentCompany(role.company);
    setCurrentLocation(role.location);
    setCurrentRole(role.current_role);
    setCurrentStartDate(dayjs(role.start_date));
    setCurrentEndDate(dayjs(role.end_date));
    setCurrentDescription(role.description);
    setExperienceModal(true);
    setActivateDeletion(true);
  };

  const handleEditCert = (cert) => {
    setCurrentCertTitle(cert.title);
    setCurrentIssuedBy(cert.issued_by);
    setCurrentCertDateStart(dayjs(cert.start_date));
    setCurrentCertDateEnd(dayjs(cert.end_date));
    setCertificationsModal(true);
    setActivateCertDeletion(true);
  };

  const updateCurrentCert = () => {
    const cert = {
      title: currentCertTitle,
      issued_by: currentIssuedBy,
      start_date: currentCertDateStart
        ? new Date(currentCertDateStart).toISOString()
        : null,
      end_date: currentCertDateEnd
        ? new Date(currentCertDateEnd).toISOString()
        : null,
    };
    const updatedCerts = certifications.map((item) => {
      if (item.title === cert.title && item.start_date === cert.start_date) {
        // Assuming combination of title and company is unique
        return { ...item, ...cert }; // This ensures any additional properties in item are preserved
      }
      return item;
    });
    setCertifications(updatedCerts);
    setCertificationsModal(false);
  };

  const updateCurrentRole = () => {
    const role = {
      title: currentJobTitle,
      company: currentCompany,
      location: currentLocation,
      current_role: currentRole,
      start_date: currentStartDate
        ? new Date(currentStartDate).toISOString()
        : null,
      end_date: currentEndDate ? new Date(currentEndDate).toISOString() : null,
      description: currentDescription,
    };
    const updatedExperience = experience.map((item) => {
      if (item.title === role.title && item.company === role.company) {
        // Assuming combination of title and company is unique
        return { ...item, ...role }; // This ensures any additional properties in item are preserved
      }
      return item;
    });
    setExperience(updatedExperience);
    setExperienceModal(false);
  };

  const removeCurrentCert = () => {
    const updatedCerts = certifications.filter(
      (item) =>
        !(item.title === currentCertTitle && item.issued_by === currentIssuedBy) // Assuming combination of title and company is unique
    );
    setCertifications(updatedCerts);
    setCertificationsModal(false);
  };

  const removeCurrentRole = () => {
    const updatedExperience = experience.filter(
      (item) =>
        !(item.title === currentJobTitle && item.company === currentCompany) // Assuming combination of title and company is unique
    );
    setExperience(updatedExperience);
    setExperienceModal(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="profile">
        <div className="profile-bubble">
          <div className="top-section">
            <Dialog
              sx={{
                "& .MuiDialog-paper": {
                  // Targeting the Dialog's paper component
                  width: "80%", // Adjust the size as needed
                  maxWidth: "600px", // Maximum size
                  padding: "16px", // Apply padding to the Dialog
                  borderRadius: "20px", // Optional: Round the corners
                },
              }}
              open={open}
              onClose={handleClose}
            >
              <DialogTitle sx={{ fontFamily: "Urbanist" }}>
                Edit Profile Information
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <div className="profile-label">First Name</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profile-label">Last Name</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profile-label">City</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profile-label">State</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div className="profile-label">Instagram</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={instagram}
                      onChange={(e) => setInstagram(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={saveProfileDialog}
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div className="profile-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
              }}
            >
              <div className="img-container">
                <div className="profile-img">
                  {profilePhoto ? (
                    <Avatar className="avatar" src={profilePhoto}></Avatar>
                  ) : (
                    <Avatar className="avatar">
                      <PersonIcon className="avatar-img" />
                    </Avatar>
                  )}
                </div>
                {editMode && !upload && (
                  <IconButton
                    component="label"
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "2rem",
                      height: "2rem",
                      boxShadow: "0px 0px 8px #00000026",
                    }}
                    color="black"
                  >
                    <PhotoCameraIcon sx={{ fontSize: "1.2rem" }} />
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                  </IconButton>
                )}
                {upload && editMode && (
                  <IconButton
                    onClick={uploadProfilePhoto}
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                    }}
                  >
                    <SaveIcon sx={{ fontSize: "1.2rem", color: "#FD9E9E" }} />
                  </IconButton>
                )}
              </div>

              <div className="profile-details">
                {user && (user.first_name || user.last_name) && (
                  <div className="name">
                    {user.first_name && `${user.first_name} `}
                    {user.last_name && user.last_name}
                  </div>
                )}

                {user && (user.location_city || user.location_state) && (
                  <div className="location">
                    {user.location_city && `${user.location_city}, `}
                    {user.location_state && user.location_state}
                  </div>
                )}
              </div>
            </div>
            <div className="profile-icons-container">
              {editMode && (
                <IconButton
                  sx={{
                    backgroundColor: "#fafbfc",
                    mt: 4,
                    mr: 2,
                    width: "1.5rem",
                    height: "1.5rem",
                    boxShadow: "0px 0px 8px #00000026",
                  }}
                  color="black"
                  onClick={handleOpen}
                >
                  <EditIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              )}
              {user && user.instagram && (
                <IconButton
                  sx={{
                    backgroundColor: "#fafbfc",
                    mt: 4,
                    mr: 2,
                    width: "1.5rem",
                    height: "1.5rem",
                    boxShadow: "0px 0px 8px #00000026",
                  }}
                  color="black"
                  onClick={goToInstagram}
                >
                  <InstagramIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              )}
            </div>
          </div>
        </div>
        <div className="bubble">
          <div className="resume">
            <div className="header">
              <div className="title">About Me</div>
              {editMode && !editAboutMe && (
                <IconButton
                  sx={{
                    backgroundColor: "#fafbfc",
                    width: "1.8rem",
                    height: "1.8rem",
                    boxShadow: "0px 0px 8px #00000026",
                  }}
                  color="black"
                  onClick={openAboutMe}
                >
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              )}
              {editMode && editAboutMe && (
                <div>
                  {" "}
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                      mr: 2,
                    }}
                    color="black"
                    onClick={closeAboutMe}
                  >
                    <CloseIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                    }}
                    color="black"
                    onClick={saveAboutMe}
                  >
                    <SaveIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </div>
              )}
            </div>

            {user && !editAboutMe && !aboutMe && (
              <div className="body">No summary to show</div>
            )}
            {user && !editAboutMe && aboutMe && (
              <div className="body">{aboutMe}</div>
            )}
            {editAboutMe && (
              <TextField
                multiline
                rows={6}
                fullWidth
                value={aboutMe}
                onChange={(e) => setAboutMe(e.target.value)}
                sx={{
                  marginTop: "20px",
                  fontFamily: "Urbanist",
                  fontSize: "10px",
                  "& .MuiOutlinedInput-root": {
                    // Default border color
                    "& fieldset": {
                      borderColor: "black",
                    },
                    // Hover border color
                    "&:hover fieldset": {
                      borderColor: "black",
                    },
                    // Focused border color
                    "&.Mui-focused fieldset": {
                      borderColor: "black",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    fontFamily: "Urbanist",
                    fontSize: "14px",
                  },
                }}
              />
            )}
          </div>
        </div>
        <div className="bubble">
          <div className="resume">
            <div className="header">
              <div className="title">Experience</div>
              {editMode && editExperience && (
                <div>
                  {" "}
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                      mr: 2,
                    }}
                    color="black"
                    onClick={openExperienceModal}
                  >
                    <AddIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                      mr: 2,
                    }}
                    color="black"
                    onClick={closeEditExperience}
                  >
                    <CloseIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                    }}
                    color="black"
                    onClick={saveExperience}
                  >
                    <SaveIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </div>
              )}
              {editMode && !editExperience && (
                <IconButton
                  sx={{
                    backgroundColor: "#fafbfc",
                    width: "1.8rem",
                    height: "1.8rem",
                    boxShadow: "0px 0px 8px #00000026",
                  }}
                  onClick={handleEditExperience}
                >
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              )}
            </div>

            {user && experience.length < 1 && (
              <div className="body">No experience to show</div>
            )}
            {user &&
              experience.length > 0 &&
              experience.map((role) => {
                return (
                  <div key={role.title} className="experience">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <div className="role">{role.title}</div>
                      {editExperience && editMode && (
                        <IconButton
                          sx={{
                            backgroundColor: "#fafbfc",
                            width: "1.4rem",
                            height: "1.4rem",
                            boxShadow: "0px 0px 8px #00000026",
                            ml: 2,
                          }}
                          onClick={() => handleEditRole(role)}
                        >
                          <EditIcon sx={{ fontSize: "0.8rem" }} />
                        </IconButton>
                      )}
                    </div>
                    <div className="company">
                      <BusinessIcon
                        sx={{ mr: 1, color: "gray", height: "14px" }}
                      />
                      {role.company}
                    </div>
                    <div className="location">
                      <PlaceIcon
                        sx={{ mr: 1, color: "gray", height: "14px" }}
                      />
                      {role.location}
                    </div>
                    <div className="dates">
                      {" "}
                      <CalendarTodayIcon
                        sx={{ mr: 1, color: "gray", height: "14px" }}
                      />
                      {dayjs(role.start_date).format("MMM YYYY")} -{" "}
                      {dayjs(role.end_date).format("MMM YYYY")}
                    </div>
                    <div
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: role.description.replace(/\n/g, "<br>"),
                      }}
                    />
                  </div>
                );
              })}
            <Dialog
              sx={{
                "& .MuiDialog-paper": {
                  // Targeting the Dialog's paper component
                  width: "80%", // Adjust the size as needed
                  maxWidth: "700px", // Maximum size
                  padding: "20px", // Apply padding to the Dialog
                  borderRadius: "20px", // Optional: Round the corners
                },
              }}
              open={experienceModal}
              onClose={closeExperienceModal}
            >
              <DialogTitle sx={{ fontFamily: "Urbanist" }}>
                Add Experience
              </DialogTitle>
              <DialogContent sx={{ marginRight: "10px" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <div className="profile-label">Job Title</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={currentJobTitle}
                      onChange={(e) => setCurrentJobTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="profile-label">Company Name</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={currentCompany}
                      onChange={(e) => setCurrentCompany(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="profile-label">Location</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={currentLocation}
                      placeholder="City, State"
                      onChange={(e) => setCurrentLocation(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={currentRole} // Controlled component
                          onChange={handleRoleChange}
                          name="currentRole"
                          sx={{
                            "&.Mui-checked": {
                              color: "#000000", // Custom color when checked
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          style={{
                            fontFamily: "Urbanist",
                            fontWeight: "300",
                            fontSize: "14px",
                          }}
                        >
                          I currently work here
                        </div>
                      }
                    />
                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={6}>
                      <div className="profile-label">Start Date</div>
                      <DatePicker
                        value={currentStartDate}
                        onChange={handleStartDateChange}
                        format="MMM YYYY"
                        views={["month", "year"]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div className="profile-label">End Date</div>
                      <DatePicker
                        disabled={currentRole}
                        value={currentEndDate}
                        onChange={handleEndDateChange}
                        format="MMM YYYY"
                        views={["month", "year"]}
                      />
                    </Grid>
                  </LocalizationProvider>

                  <Grid item xs={12}>
                    <div className="profile-label">Description</div>
                    <TextField
                      multiline
                      rows={6}
                      fullWidth
                      value={currentDescription}
                      onChange={(e) => setCurrentDescription(e.target.value)}
                      sx={{
                        marginTop: "20px",
                        fontFamily: "Urbanist",
                        "& .MuiOutlinedInput-root": {
                          // Default border color
                          "& fieldset": {
                            borderColor: "black",
                          },
                          // Hover border color
                          "&:hover fieldset": {
                            borderColor: "black",
                          },
                          // Focused border color
                          "&.Mui-focused fieldset": {
                            borderColor: "black",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                  onClick={closeExperienceModal}
                >
                  Cancel
                </Button>
                {activateDeletion && (
                  <Button
                    onClick={updateCurrentRole}
                    sx={{ fontFamily: "Urbanist", color: "black" }}
                  >
                    Save
                  </Button>
                )}
                {activateDeletion && (
                  <Button
                    onClick={removeCurrentRole}
                    sx={{ fontFamily: "Urbanist", color: "black" }}
                  >
                    Remove
                  </Button>
                )}
                {!activateDeletion && (
                  <Button
                    onClick={addNewRole}
                    sx={{ fontFamily: "Urbanist", color: "black" }}
                  >
                    Add
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <div className="bubble">
          <div className="resume">
            <div className="header">
              <div className="title">Licenses & Certifications</div>
              {editMode && editCertifications && (
                <div>
                  {" "}
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                      mr: 2,
                    }}
                    color="black"
                    onClick={openCertificationsModal}
                  >
                    <AddIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                      mr: 2,
                    }}
                    color="black"
                    onClick={closeEditCertifications}
                  >
                    <CloseIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                    }}
                    color="black"
                    onClick={saveCertifications}
                  >
                    <SaveIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </div>
              )}
              {editMode && !editCertifications && (
                <IconButton
                  sx={{
                    backgroundColor: "#fafbfc",
                    width: "1.8rem",
                    height: "1.8rem",
                    boxShadow: "0px 0px 8px #00000026",
                  }}
                  onClick={handleEditCertifications}
                >
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              )}
            </div>

            {user && certifications.length < 1 && (
              <div className="body">No certifications or licenses to show</div>
            )}

            {user &&
              certifications.length > 0 &&
              certifications.map((cert) => {
                return (
                  <div key={cert.title} className="certifications">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <div className="role">{cert.title}</div>
                      {editCertifications && editMode && (
                        <IconButton
                          sx={{
                            backgroundColor: "#fafbfc",
                            width: "1.4rem",
                            height: "1.4rem",
                            boxShadow: "0px 0px 8px #00000026",
                            ml: 2,
                          }}
                          onClick={() => handleEditCert(cert)}
                        >
                          <EditIcon sx={{ fontSize: "0.8rem" }} />
                        </IconButton>
                      )}
                    </div>
                    <div className="issued-by">
                      <BusinessIcon
                        sx={{ mr: 1, color: "gray", height: "14px" }}
                      />
                      {cert.issued_by}
                    </div>
                    <div className="valid-dates">
                      {" "}
                      <CalendarTodayIcon
                        sx={{ mr: 1, color: "gray", height: "14px" }}
                      />
                      {dayjs(cert.start_date).format("MMM YYYY")} -{" "}
                      {dayjs(cert.end_date).format("MMM YYYY")}
                    </div>
                  </div>
                );
              })}
            <Dialog
              sx={{
                "& .MuiDialog-paper": {
                  // Targeting the Dialog's paper component
                  width: "80%", // Adjust the size as needed
                  maxWidth: "700px", // Maximum size
                  padding: "20px", // Apply padding to the Dialog
                  borderRadius: "20px", // Optional: Round the corners
                },
              }}
              open={certificationsModal}
              onClose={closeCertificationsModal}
            >
              <DialogTitle sx={{ fontFamily: "Urbanist" }}>
                Add Certification or License
              </DialogTitle>
              <DialogContent sx={{ marginRight: "10px" }}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <div className="profile-label">Title</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={currentCertTitle}
                      onChange={(e) => setCurrentCertTitle(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="profile-label">Issued By</div>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={currentIssuedBy}
                      onChange={(e) => setCurrentIssuedBy(e.target.value)}
                    />
                  </Grid>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={6}>
                      <div className="profile-label">Issued On</div>
                      <DatePicker
                        value={currentCertDateStart}
                        onChange={handleCertStartDateChange}
                        format="MMM YYYY"
                        views={["month", "year"]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div className="profile-label">Expires</div>
                      <DatePicker
                        value={currentCertDateEnd}
                        onChange={handleCertEndDateChange}
                        format="MMM YYYY"
                        views={["month", "year"]}
                      />
                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ fontFamily: "Urbanist", color: "black" }}
                  onClick={closeCertificationsModal}
                >
                  Cancel
                </Button>
                {activateCertDeletion && (
                  <Button
                    onClick={updateCurrentCert}
                    sx={{ fontFamily: "Urbanist", color: "black" }}
                  >
                    Save
                  </Button>
                )}
                {activateCertDeletion && (
                  <Button
                    onClick={removeCurrentCert}
                    sx={{ fontFamily: "Urbanist", color: "black" }}
                  >
                    Remove
                  </Button>
                )}
                {!activateCertDeletion && (
                  <Button
                    onClick={addNewCert}
                    sx={{ fontFamily: "Urbanist", color: "black" }}
                  >
                    Add
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <div className="bubble">
          <div className="resume">
            <div className="header">
              <div className="title">Portfolio</div>
              {editMode && !editPortfolio && (
                <IconButton
                  sx={{
                    backgroundColor: "#fafbfc",
                    width: "1.8rem",
                    height: "1.8rem",
                    boxShadow: "0px 0px 8px #00000026",
                  }}
                  color="black"
                  onClick={openPortfolio}
                >
                  <EditIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              )}
              {editMode && editPortfolio && (
                <div>
                  {" "}
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                      mr: 2,
                    }}
                    color="black"
                    onClick={closePortfolio}
                  >
                    <CloseIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                  <IconButton
                    sx={{
                      backgroundColor: "#fafbfc",
                      width: "1.8rem",
                      height: "1.8rem",
                      boxShadow: "0px 0px 8px #00000026",
                    }}
                    color="black"
                    onClick={savePortfolio}
                  >
                    <SaveIcon sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </div>
              )}
            </div>
            <div className="portfolio">
              {user && images.length < 1 && !editPortfolio && (
                <div className="body">No images to display</div>
              )}

              {user &&
                images.length > 0 &&
                !editPortfolio &&
                images.map((image) => {
                  return (
                    <img
                      key={image}
                      className="portfolio-image"
                      src={`https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${profileId}/${image}`}
                    />
                  );
                })}
            </div>

            {editMode && editPortfolio && (
              <SortableTool sendImages={handleImages} />
            )}
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default Profile;
