import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { supabase } from "./SupabaseClient";
import { useNavigate, useParams } from "react-router-dom";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Jobs from "./Jobs";
import Settings from "./Settings";
import { checkUserSignedIn } from "./AuthUtils";
import Business from "./Business";
import Messages from "./Messages";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";

const Dashboard = () => {
  let navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [active, setActive] = useState("jobs");
  const [recruiter, setRecruiter] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleRecruiterChange = async (event) => {
    setRecruiter(!recruiter);

    if (event.target.checked) {
      const { data, error } = await supabase
        .from("Users")
        .update({ recruiter: true })
        .eq("id", user.id);

      if (error) {
        console.error("Error updating user data:", error);
      }
    } else if (!event.target.checked) {
      const { data, error } = await supabase
        .from("Users")
        .update({ recruiter: false })
        .eq("id", user.id);

      if (error) {
        console.error("Error updating user data:", error);
      }
    }
  };

  useEffect(() => {
    checkUserSignedIn().then(async ({ session, error }) => {
      if (error) {
        console.error("Error checking user sign-in status", error);
        // Handle error (e.g., show a message or redirect to a sign-in page)
      } else {
        const { data, error } = await supabase
          .from("Users")
          .select()
          .eq("id", session.user.id);

        if (error) {
          console.error("Error fetching user data:", error);
        } else {
          setUser(data[0]);
          if (data[0].recruiter) {
            setRecruiter(true);
          }
        }
      }
    });
  }, []);

  return (
    <Box className="dashboard-wrapper">
      <Box className="dashboard">
        <div className="mobile-sidebar">
          <IconButton onClick={() => setActive("jobs")} aria-label="jobs">
            <WorkOutlineIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => setActive("messages")}
            aria-label="messages"
          >
            <EmailOutlinedIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => setActive("settings")}
            aria-label="settings"
          >
            <SettingsIcon
              sx={{
                fontSize: "20px",
              }}
            />
          </IconButton>

          <div className="switch-wrapper">
            <Switch
              color="default"
              size="small"
              checked={recruiter}
              onChange={handleRecruiterChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div className="switch">
              {recruiter ? "Recruiter" : "Job Seeker"}
            </div>
          </div>
        </div>
        <div className="sidebar">
          <div
            onClick={() => setActive("jobs")}
            className="sidebar-btn"
            style={{
              backgroundColor: active === "jobs" ? "#000000" : "transparent",
              color: active === "jobs" ? "white" : "#747686",
            }}
          >
            <WorkOutlineIcon
              sx={{
                fontSize: "20px",
                color: active === "jobs" ? "white" : "black",
              }}
            />
            Jobs
          </div>
          <div
            onClick={() => setActive("messages")}
            className="sidebar-btn"
            style={{
              backgroundColor:
                active === "messages" ? "#000000" : "transparent",
              color: active === "messages" ? "white" : "#747686",
            }}
          >
            <EmailOutlinedIcon
              sx={{
                fontSize: "20px",
                color: active === "messages" ? "white" : "black",
              }}
            />
            Messages
          </div>
          <div
            onClick={() => setActive("settings")}
            className="sidebar-btn"
            style={{
              backgroundColor:
                active === "settings" ? "#000000" : "transparent",
              color: active === "settings" ? "white" : "#747686",
            }}
          >
            <SettingsIcon
              sx={{
                fontSize: "20px",
                color: active === "settings" ? "white" : "black",
              }}
            />
            Settings
          </div>
          <div className="switch-wrapper">
            <Switch
              color="default"
              checked={recruiter}
              onChange={handleRecruiterChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div className="switch">
              {recruiter ? "Recruiter" : "Job Seeker"}
            </div>
          </div>
        </div>
        <div className="main">
          {active === "jobs" && user && !recruiter && <Jobs user={user} />}
          {active === "jobs" && user && recruiter && <Business user={user} />}
          {active === "messages" && user && <Messages user={user} />}
          {active === "settings" && user && <Settings user={user} />}
        </div>
      </Box>
    </Box>
  );
};

export default Dashboard;
