import React from "react";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import CheckIcon from "@mui/icons-material/Check";

const Pricing = () => {
  const [active, setActive] = useState("businesses");
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="pricing">
      <div className="title fadeInAnimation">Simple, Transparent Pricing</div>
      <div className="btn-wrapper">
        <button
          style={{
            backgroundColor: active === "businesses" ? "#000000" : "#ffffff",
            color: active === "businesses" ? "#ffffff" : "#000000",
          }}
          onClick={() => setActive("businesses")}
        >
          Businesses
        </button>{" "}
        <Divider orientation="vertical" variant="middle" flexItem />{" "}
        <button
          style={{
            backgroundColor: active === "pro" ? "#000000" : "#ffffff",
            color: active === "pro" ? "#ffffff" : "#000000",
          }}
          onClick={() => setActive("pro")}
        >
          Professionals
        </button>
      </div>
      {active === "businesses" && (
        <div className="card-container">
          <div className="card">
            <div>
              <div className="card-title-wrapper">
                <div className="card-title">$300</div>
                <div className="card-subtitle">per post</div>
              </div>
              <Divider
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                flexItem
              />
              <div className="price-option-container">
                <div className="price-option">
                  <Avatar
                    sx={{ bgcolor: "#000000", width: "20px", height: "20px" }}
                  >
                    <CheckIcon sx={{ width: "10px", height: "10px" }} />
                  </Avatar>
                  30 day job listing
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="card-rec">
            <div>
              <div className="card-title-wrapper">
                <div className="card-title">$500</div>
                <div className="card-subtitle">per post</div>
              </div>
              <Divider
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                flexItem
              />
              <div className="price-option-container">
                <div className="price-option">
                  <Avatar
                    sx={{ bgcolor: "#000000", width: "20px", height: "20px" }}
                  >
                    <CheckIcon sx={{ width: "10px", height: "10px" }} />
                  </Avatar>
                  60 day job listing{" "}
                </div>{" "}
              </div>
            </div>

            <button>Recommended</button>
          </div>
          <div className="card">
            <div>
              <div className="card-title-wrapper">
                <div className="card-title">$720</div>
                <div className="card-subtitle">per post</div>
              </div>
              <Divider
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                flexItem
              />
              <div className="price-option-container">
                <div className="price-option">
                  <Avatar
                    sx={{ bgcolor: "#000000", width: "20px", height: "20px" }}
                  >
                    <CheckIcon sx={{ width: "10px", height: "10px" }} />
                  </Avatar>
                  90 day job listing
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      )}
      {active === "pro" && (
        <div className="card-container">
          <div className="card" style={{ height: "190px" }}>
            <div>
              <div className="card-title-wrapper">
                <div className="card-title">Free</div>
                <div className="card-subtitle">forever</div>
              </div>
              <Divider
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                flexItem
              />
              <div className="price-option-container">
                <div className="price-option">
                  <Avatar
                    sx={{ bgcolor: "#000000", width: "20px", height: "20px" }}
                  >
                    <CheckIcon sx={{ width: "10px", height: "10px" }} />
                  </Avatar>
                  10 applications per month
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="card" style={{ height: "190px" }}>
            <div>
              <div className="card-title-wrapper">
                <div className="card-title">$30</div>
                <div className="card-subtitle">per month</div>
              </div>
              <Divider
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
                flexItem
              />
              <div className="price-option-container">
                <div className="price-option">
                  <Avatar
                    sx={{ bgcolor: "#000000", width: "20px", height: "20px" }}
                  >
                    <CheckIcon sx={{ width: "10px", height: "10px" }} />
                  </Avatar>
                  Show up first to employers
                </div>
                <div className="price-option">
                  <Avatar
                    sx={{ bgcolor: "#000000", width: "20px", height: "20px" }}
                  >
                    <CheckIcon sx={{ width: "10px", height: "10px" }} />
                  </Avatar>
                  Unlimited applications
                </div>
              </div>
            </div>

            <button>Recommended</button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default Pricing;
