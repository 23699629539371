import * as React from "react";
import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { checkUserSignedIn } from "./AuthUtils";
import { supabase } from "./SupabaseClient";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import { amplitude } from "./Amplitude";

const pages = ["jobs", "dashboard", "pricing", "about us"];
//const settings = ["Profile", "Account", "Dashboard", "Logout"];

function TopAppBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [signedIn, setSignedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  //const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const [notifs, setNotifs] = useState([]);
  // const [notifsCount, setNotifsCount] = useState(0);

  useEffect(() => {
    checkUserSignedIn().then(async ({ session, error }) => {
      if (error) {
        console.error("Error checking user sign-in status", error);
        // Handle error (e.g., show a message or redirect to a sign-in page)
      } else {
        if (!session) {
          setSignedIn(false);
          return;
        } else {
          setSignedIn(true);
          setUserId(session.user.id);
          const { data, error } = await supabase
            .from("Users")
            .select("profile_picture")
            .eq("id", session.user.id);

          if (error) {
            console.error("Error fetching user data:", error);
          } else {
            setProfilePicture(
              `https://mfvvqaebomfkjwqtvsix.supabase.co/storage/v1/object/public/media/${session.user.id}/${data[0].profile_picture}`
            );
          }
        }
      }
    });
  }, []);

  // const getNotifs = async () => {
  //   const { data, error } = await supabase
  //     .from("Notifications")
  //     .select()
  //     .eq("user_id", userId);

  //   if (error) {
  //     console.error("Error fetching notifications:", error);
  //   } else {
  //     setNotifs(data);
  //   }
  // };

  // const clearNotifs = async () => {
  //   const { data, error } = await supabase
  //     .from("Notifications")
  //     .delete()
  //     .eq("user_id", userId);

  //   if (error) {
  //     console.error("Error clearing notifications:", error);
  //   } else {
  //     setNotifs([]);
  //     setNotifsCount(0);
  //   }
  // };

  const navigateToHome = () => {
    navigate("/");
  };

  const goToProfile = () => {
    setAnchorElUser(null);
    navigate(`/profile/${userId}`);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    if (!page) {
      navigateToHome();
    }

    if (page === "jobs") {
      navigateToHome();
    } else if (page === "about us") {
      navigate("/aboutus");
    } else {
      navigate("/" + page);
    }
  };

  const handleCloseMobileNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const goToDashboard = () => {
    setAnchorElUser(null);
    navigate("/dashboard");
  };

  const handleLogOut = async () => {
    setAnchorElUser(null);

    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log("Error logging out:", error.message);
    } else {
      amplitude.reset();
      navigate("/signin");
    }
  };

  // const handleNotifsClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleNotifsClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#ffffff",
        boxShadow: "none",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            justifyContent: { xs: "space-between", sm: "space-between" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              alt="logo"
              onClick={navigateToHome}
              src="/images/Logo.png"
              style={{
                display: { xs: "none", md: "flex" },
                marginRight: "10px",
                width: 30,
                height: 24,
                cursor: "pointer",
              }}
            ></img>
            <div
              onClick={navigateToHome}
              className="top-app-bar-text"
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              Aesthetics Portal
            </div>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) =>
              !signedIn && page === "dashboard" ? null : (
                <Button
                  key={page}
                  className="top-app-bar"
                  onClick={() => handleCloseNavMenu(page)}
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  {page}
                </Button>
              )
            )}
          </Box>

          <Box sx={{ flexGrow: 0, display: "flex" }}>
            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, mr: 2 }}
            >
              <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseMobileNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  color: "black",
                }}
              >
                {pages.map((page) =>
                  !signedIn && page === "dashboard" ? null : (
                    <MenuItem
                      key={page}
                      onClick={() => handleCloseNavMenu(page)}
                    >
                      <Typography
                        className="top-app-bar"
                        sx={{ color: "black", textTransform: "capitalize" }}
                        textAlign="center"
                      >
                        {page}
                      </Typography>
                    </MenuItem>
                  )
                )}
              </Menu>
            </Box>
            {/* {signedIn && (
              <IconButton
                size="large"
                color="black"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleNotifsClick}
                sx={{ marginRight: "20px" }}
              >
                <Badge badgeContent={notifsCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleNotifsClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleNotifsClose}>Notif 1</MenuItem>
              <MenuItem onClick={handleNotifsClose}>Notif 2</MenuItem>
              <MenuItem onClick={handleNotifsClose}>Notif 3</MenuItem>
            </Menu> */}
            {signedIn ? (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    sx={{ maxWidth: "30px", maxHeight: "30px" }}
                    src={signedIn ? profilePicture : null}
                  />
                </IconButton>
              </Tooltip>
            ) : (
              <button
                className="topappbar-button"
                onClick={() => navigate("/signin")}
              >
                Login/Signup
              </button>
            )}

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={goToProfile} key="profile">
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={goToDashboard} key="dashboard">
                <Typography textAlign="center">Dashboard</Typography>
              </MenuItem>
              <MenuItem key="logout" onClick={handleLogOut}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopAppBar;
