import React from "react";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useNavigate } from "react-router-dom";
import Applicants from "./Applicants";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import NewPost from "./NewPost";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fetchPlace } from "./FetchPlace";
import { createTheme, ThemeProvider } from "@mui/material";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import Radio from "@mui/material/Radio";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { amplitude } from "./Amplitude";

const types = [
  {
    value: "Full Time",
    label: "Full Time",
  },
  {
    value: "Part Time",
    label: "Part Time",
  },
  {
    value: "Contract",
    label: "Contract",
  },
  {
    value: "Internship",
    label: "Internship",
  },
];

const stateAcronyms = [
  "AL", // Alabama
  "AK", // Alaska
  "AZ", // Arizona
  "AR", // Arkansas
  "CA", // California
  "CO", // Colorado
  "CT", // Connecticut
  "DE", // Delaware
  "FL", // Florida
  "GA", // Georgia
  "HI", // Hawaii
  "ID", // Idaho
  "IL", // Illinois
  "IN", // Indiana
  "IA", // Iowa
  "KS", // Kansas
  "KY", // Kentucky
  "LA", // Louisiana
  "ME", // Maine
  "MD", // Maryland
  "MA", // Massachusetts
  "MI", // Michigan
  "MN", // Minnesota
  "MS", // Mississippi
  "MO", // Missouri
  "MT", // Montana
  "NE", // Nebraska
  "NV", // Nevada
  "NH", // New Hampshire
  "NJ", // New Jersey
  "NM", // New Mexico
  "NY", // New York
  "NC", // North Carolina
  "ND", // North Dakota
  "OH", // Ohio
  "OK", // Oklahoma
  "OR", // Oregon
  "PA", // Pennsylvania
  "RI", // Rhode Island
  "SC", // South Carolina
  "SD", // South Dakota
  "TN", // Tennessee
  "TX", // Texas
  "UT", // Utah
  "VT", // Vermont
  "VA", // Virginia
  "WA", // Washington
  "WV", // West Virginia
  "WI", // Wisconsin
  "WY", // Wyoming
];

let Size = ReactQuill.Quill.import("attributors/style/size");
Size.whitelist = [
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
];
ReactQuill.Quill.register(Size, true);

const modules = {
  toolbar: [
    // ... (other toolbar options)
    [{ size: Size.whitelist }],
    [{ align: [] }], // Dropdown with text align options
    ["bold", "italic", "underline", "strike"], // Toggles for bold, italic, underline, and strikethrough
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#000000",
  },
  fontFamily: "Urbanist",
  fontSize: "12px",
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Urbanist !important",
  fontWeight: "300 !important",
  fontSize: "16px !important",
  marginRight: "20px",
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#000000",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const theme = createTheme({
  components: {
    Typography: {
      styleOverrides: {
        root: {
          fontFamily: "Urbanist",
          fontSize: "14px",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          // Default state
          "&.Mui-active": {
            color: "black", // Active state color
          },
          "&.Mui-completed": {
            color: "black", // Completed state color
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: "Urbanist",
          fontWeight: "300 !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "Urbanist",
          fontSize: "14px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
        },
      },
    },
  },
});

const stripePromise = loadStripe(
  "pk_live_51L8CaSKvsvXbaxMgDOF2oOUzhzWGjD53lzRJamrwKhJNj7HZ6CLL6Yn3u4qzx7s2dJf1QVotb5Upk5GrhfwC4gZt00nDF5SnFf"
);

const appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: "#000000",
  },
};

const Business = ({ user }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("postings");
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [jobPostings, setJobPostings] = useState([]);
  const [showApplicants, setShowApplicants] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentEditJob, setCurrentEditJob] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [description, setDescription] = useState("");
  const [jobType, setJobType] = useState(["Full Time"]);
  const [autocompleteCities, setAutocompleteCities] = useState([]);
  const [autocompleteErr, setAutocompleteErr] = useState("");
  const [editModalStage, setEditModalStage] = useState("jobs");
  const [selectedValue, setSelectedValue] = useState("");
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [total, setTotal] = useState(0);
  const [clientSecret, setClientSecret] = useState("");

  const options = {
    clientSecret,
    appearance,
  };

  const fetchClientSecret = async () => {
    try {
      const response = await fetch(
        "https://urdyzgbzu3.execute-api.us-west-2.amazonaws.com/prod/intent",
        {
          method: "POST",
          body: JSON.stringify({
            amount: Number(total),
            customer: user.stripe_id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setClientSecret(result.clientSecret);
      setEditModalStage("payment");
    } catch (error) {
      console.error("Create session failed:", error);
    }
  };

  const handleCityChange = async (value) => {
    setCity(value);
    if (!value) return; // Use the argument `value` instead of `location` for immediate effect

    try {
      const res = await fetchPlace(value);

      if (res.features) {
        // Extract city names from the response
        const cityNames = res.features.map((place) => place.text); // Using `text` for city names

        // Update autocomplete suggestions
        // Ensure no duplicates are added, assuming `autocompleteCities` is a state variable
        const newCities = cityNames.filter(
          (city) => !autocompleteCities.includes(city)
        );
        setAutocompleteCities((prevCities) => [...prevCities, ...newCities]);
      }
    } catch (error) {
      console.error("Failed to fetch places:", error);
      setAutocompleteErr("Failed to fetch places");
    }
  };

  useEffect(() => {
    let total;
    if (selectedValue === "30") {
      if (numberOfDays > 0) {
        total = 300 + numberOfDays * 20;
      } else {
        total = 300;
      }
    } else if (selectedValue === "60") {
      if (numberOfDays > 0) {
        total = 500 + numberOfDays * 20;
      } else {
        total = 500;
      }
    } else if (selectedValue === "90") {
      if (numberOfDays > 0) {
        total = 720 + numberOfDays * 20;
      } else {
        total = 720;
      }
    } else if (numberOfDays > 0) {
      total = numberOfDays * 20;
    } else {
      total = 0;
    }

    setTotal(total);
  }, [selectedValue, numberOfDays]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToApplicants = (id, apps) => {
    if (apps === 0) return;
    setShowApplicants(true);
    setCurrentId(id);
  };

  const getJobPostings = async (id) => {
    setInProgress(true);
    const { data, error } = await supabase
      .from("Jobs")
      .select()
      .eq("job_owner_id", id);

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      setInProgress(false);
      setJobPostings(data);
    }
  };

  const deleteJob = async () => {
    const { data, error } = await supabase
      .from("Jobs")
      .delete()
      .eq("id", currentJobId);
    if (error) {
      console.error("Error deleting job:", error);
    } else {
      setShowDeleteModal(false);
      getJobPostings(user.id);
    }
  };

  useEffect(() => {
    if (user) {
      getJobPostings(user.id);
    }
  }, []);

  const handleGoBack = () => {
    setShowApplicants(false);
  };

  const handleDelete = async (id) => {
    setCurrentJobId(id);
    setShowDeleteModal(true);
  };

  const handleEdit = (job) => {
    setCompanyName(job.company_name);
    setJobTitle(job.job_name);
    setCity(job.job_location_city);
    setState(job.job_location_state);
    setDescription(job.job_description);
    setJobType(job.job_type);
    setShowEditModal(true);
    setCurrentEditJob(job);
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
    setEditModalStage("jobs");
    setNumberOfDays(0);
    setSelectedValue("");
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
  };

  const calculateDays = (expiration) => {
    const expiresAt = dayjs(expiration);
    const today = dayjs();
    return expiresAt.diff(today, "days");
  };

  const addPaymentRecord = async () => {
    let productInfo;
    let promoInfo;
    let product;
    if (selectedValue === "30") {
      productInfo = "30 Day Extension";
    } else if (selectedValue === "60") {
      productInfo = "60 Day Extension";
    } else if (selectedValue === "90") {
      productInfo = "90 Day Extension";
    }

    if (numberOfDays > 0) {
      promoInfo = `${numberOfDays} day promotion`;
    }

    if (productInfo && promoInfo) {
      product = `${productInfo} + ${promoInfo}`;
    } else if (productInfo) {
      product = productInfo;
    } else if (promoInfo) {
      product = promoInfo;
    }

    const { data, error } = await supabase.from("Payments").insert([
      {
        user_id: user.id,
        amount: total,
        product,
        email: user.email,
      },
    ]);

    if (error) {
      console.error("Error adding payment record:", error);
    }
  };

  const saveJob = async () => {
    const updateItem = {
      company_name: companyName,
      job_name: jobTitle,
      job_location_city: city,
      job_location_state: state,
      job_description: description,
      job_type: jobType,
    };

    if (
      selectedValue === "30" ||
      selectedValue === "60" ||
      selectedValue === "90"
    ) {
      const today = dayjs(currentEditJob.expires_at);
      updateItem.expires_at = today.add(Number(selectedValue), "day");
      updateItem.active = true;
    }

    if (numberOfDays > 0) {
      if (currentEditJob.promoted) {
        const today = dayjs(currentEditJob.promoted_expires_at);
        updateItem.promoted_expires_at = today.add(Number(numberOfDays), "day");
      } else {
        const today = dayjs();
        updateItem.promoted = true;
        updateItem.promoted_expires_at = today.add(Number(numberOfDays), "day");
      }
    }

    const { data, error } = await supabase
      .from("Jobs")
      .update(updateItem)
      .eq("id", currentEditJob.id);
    if (error) {
      console.error("Error updating job:", error);
    } else {
      addPaymentRecord();
      setShowEditModal(false);
      getJobPostings(user.id);
      setEditModalStage("jobs");
    }
  };

  const handleNewJobClick = () => {
    amplitude.logEvent("CREATE_NEW_JOB_CLICK", {
      id: user.id,
      email: user.email,
    });
    setValue("create");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "20px",
        }}
      >
        {showApplicants && (
          <Applicants
            jobId={currentId}
            handleGoBack={handleGoBack}
            id={user.id}
          />
        )}
        {!showApplicants && (
          <div>
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <StyledTabs value={value} onChange={handleChange}>
                <StyledTab value="postings" label="Job Postings" />
              </StyledTabs>
              <div onClick={handleNewJobClick} className="business-new-btn">
                Add New Job
              </div>
            </div>

            <div className="dashboard-jobs-container">
              {value === "postings" && (
                <div className="dashboard-jobs">
                  {jobPostings &&
                    jobPostings.length > 0 &&
                    jobPostings.map((job) => (
                      <div className="job" key={job.id}>
                        <div>
                          <div className="job-name">{job.job_name} </div>

                          <div className="job-location-container">
                            <FmdGoodIcon
                              sx={{ width: "16px", color: "gray" }}
                            ></FmdGoodIcon>
                            <div className="job-location">
                              {job.job_location_city}, {job.job_location_state}
                            </div>
                          </div>
                          <div className="job-date-container">
                            <CalendarTodayIcon
                              sx={{ width: "16px", color: "gray" }}
                            />
                            <div className="job-date">
                              {dayjs(job.created_at).format("M/D/YY")}
                            </div>
                          </div>

                          <div className="job-type-container">
                            <div className="days-left">
                              {`${calculateDays(job.expires_at)} days left`}
                            </div>

                            {job.promoted && (
                              <div className="promoted">Promoted</div>
                            )}

                            {/* {job.job_type.map((type) => {
                              return (
                                <div key={type} className="job-chip">
                                  {type}
                                </div>
                              );
                            })} */}
                          </div>
                        </div>

                        <div className="job-footer">
                          <button
                            className="applicants-btn"
                            onClick={() =>
                              goToApplicants(job.id, job.app_count)
                            }
                          >
                            View {job.app_count} Applicants
                          </button>
                          <IconButton
                            onClick={() => handleEdit(job)}
                            className="dashboard-edit-icon"
                          >
                            <EditIcon sx={{ fontSize: "1rem" }} />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete(job.id)}
                            className="dashboard-edit-icon"
                          >
                            <CloseIcon sx={{ fontSize: "1rem" }} />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  {!jobPostings.length && !inProgress && (
                    <div className="no-jobs-text">No postings to show</div>
                  )}
                  <Dialog
                    sx={{
                      "& .MuiDialog-paper": {
                        width: "80%",
                        maxWidth: "500px",
                        padding: "16px",
                        borderRadius: "20px",
                      },
                      "& .MuiBackdrop-root": {
                        backgroundColor: "rgb(0 0 0 / 50%)", // Adjust backdrop opacity here
                      },
                    }}
                    open={showDeleteModal}
                    onClose={handleDeleteModalClose}
                  >
                    <DialogTitle sx={{ fontFamily: "Urbanist" }}>
                      Are you sure you want to delete this job?
                    </DialogTitle>
                    <DialogContent
                      sx={{
                        fontFamily: "Urbanist",
                        fontWeight: "200",
                        fontSize: "14px",
                      }}
                    >
                      All applicants will be removed from this job.
                    </DialogContent>
                    <DialogActions>
                      <Button
                        sx={{ fontFamily: "Urbanist", color: "black" }}
                        onClick={handleDeleteModalClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={deleteJob}
                        sx={{ fontFamily: "Urbanist", color: "black" }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    sx={{
                      "& .MuiDialog-paper": {
                        width: "100%",
                        maxWidth: "900px",
                        height: "100%",
                        maxHeight: "550px",
                        padding: "16px",
                        borderRadius: "20px",
                      },
                      "& .MuiBackdrop-root": {
                        backgroundColor: "rgb(0 0 0 / 50%)", // Adjust backdrop opacity here
                      },
                    }}
                    open={showEditModal}
                    onClose={handleEditModalClose}
                  >
                    <DialogTitle
                      sx={{
                        fontFamily: "Urbanist",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      Edit Job
                      {currentEditJob && currentEditJob.expires_at && (
                        <div className="edit-modal-days-chip">
                          {`${calculateDays(
                            currentEditJob.expires_at
                          )} days left`}
                        </div>
                      )}
                      {currentEditJob && currentEditJob.promoted && (
                        <div className="edit-modal-promo-chip">
                          Currently Promoted
                        </div>
                      )}
                    </DialogTitle>

                    <DialogContent className="edit-post">
                      {editModalStage === "jobs" && (
                        <Grid container spacing={4}>
                          <Grid item xs={4}>
                            <div className="label">Company Name</div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={companyName}
                              onChange={(e) => setCompanyName(e.target.value)}
                            />
                            <div className="label">Job Title</div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={jobTitle}
                              onChange={(e) => setJobTitle(e.target.value)}
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                width: "100%",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "70%",
                                }}
                              >
                                {" "}
                                <div className="label">City</div>
                                <Autocomplete
                                  freeSolo
                                  size="small"
                                  fullWidth
                                  value={city}
                                  components={{
                                    EndAdornment: () => null, // This removes the end adornment
                                  }}
                                  onInputChange={(event, newInputValue) => {
                                    handleCityChange(newInputValue);
                                  }}
                                  onChange={(event, newValue) => {
                                    setCity(newValue);
                                  }}
                                  options={autocompleteCities}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      {...params}
                                      fullWidth
                                    />
                                  )}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "30%",
                                }}
                              >
                                {" "}
                                <div className="label">State</div>
                                <Autocomplete
                                  fullWidth
                                  freeSolo
                                  size="small"
                                  onChange={(event, newValue) => {
                                    setState(newValue);
                                  }}
                                  value={state}
                                  options={stateAcronyms}
                                  renderInput={(params) => (
                                    <TextField size="small" {...params} />
                                  )}
                                />
                              </div>
                            </div>

                            <div className="label">Employment Type</div>
                            <Select
                              fullWidth
                              size="small"
                              multiple
                              select
                              value={jobType}
                              onChange={(e) => setJobType(e.target.value)}
                            >
                              {types.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={8}>
                            <div className="label">Description</div>
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              value={description}
                              onChange={setDescription}
                              style={{ height: "260px", width: "550px" }}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {editModalStage === "duration" && (
                        <div className="edit-modal-wrapper">
                          <div className="duration-column">
                            <div className="days-left-title">
                              Your listing will expire in{" "}
                              {calculateDays(currentEditJob.expires_at)} days.
                              Choose an option below if you wish to add more
                              days.
                            </div>
                            <div className="duration-bubble">
                              <div className="duration-label">
                                30 Day Extension
                              </div>
                              <div className="duration-price">$300</div>

                              <Radio
                                value="30"
                                checked={selectedValue === "30"}
                                onChange={(e) =>
                                  setSelectedValue(e.target.value)
                                }
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                                sx={{
                                  color: "black", // Default color
                                  "&.Mui-checked": {
                                    color: "black", // Color when checked
                                  },
                                }}
                              />
                            </div>
                            <div className="duration-bubble">
                              <div className="duration-label">
                                60 Day Extension
                              </div>
                              <div className="duration-price">$500</div>

                              <Radio
                                value="60"
                                checked={selectedValue === "60"}
                                onChange={(e) =>
                                  setSelectedValue(e.target.value)
                                }
                                sx={{
                                  color: "black", // Default color
                                  "&.Mui-checked": {
                                    color: "black", // Color when checked
                                  },
                                }}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </div>
                            <div className="duration-bubble">
                              <div className="duration-label">
                                90 Day Extension
                              </div>
                              <div className="duration-price">$720</div>

                              <Radio
                                value="90"
                                checked={selectedValue === "90"}
                                onChange={(e) =>
                                  setSelectedValue(e.target.value)
                                }
                                sx={{
                                  color: "black", // Default color
                                  "&.Mui-checked": {
                                    color: "black", // Color when checked
                                  },
                                }}
                                name="radio-buttons"
                                inputProps={{ "aria-label": "A" }}
                              />
                            </div>
                            <button
                              onClick={() => setSelectedValue("")}
                              className="edit-modal-clear-btn"
                            >
                              Clear
                            </button>
                            <div className="total-price">
                              <div className="price-amount">Total ${total}</div>
                            </div>
                          </div>
                        </div>
                      )}
                      {editModalStage === "promotion" && (
                        <div className="edit-modal-wrapper">
                          <div className="ads">
                            <div className="ads-label-wrapper">
                              <div className="ads-label">Add Boost</div>
                              <div className="ads-label-optional">
                                *optional
                              </div>
                            </div>

                            <div className="ads-label-price">
                              Starting at $20/day
                            </div>

                            <div className="ads-bubble">
                              <TextField
                                value={numberOfDays}
                                onChange={(e) =>
                                  setNumberOfDays(e.target.value)
                                }
                                type="number"
                                variant="standard"
                                sx={{
                                  width: "80px",
                                  // Target the input font size
                                  "& .MuiInputBase-input": {
                                    fontSize: "26px", // Adjust font size here
                                  },
                                  // Target the underline before (default state)
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "black", // Keep underline black
                                  },
                                  // Target the underline after (focused state)
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "black", // Keep underline black when focused
                                  },
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <div className="days-label">Days</div>
                            </div>
                            {currentEditJob.promoted && (
                              <div className="ads-label-rec">
                                There are{" "}
                                {calculateDays(
                                  currentEditJob.promoted_expires_at
                                )}{" "}
                                days left in your promotion
                              </div>
                            )}
                            {!currentEditJob.promoted && (
                              <div className="ads-label-rec">
                                Your job is not being promoted at this time
                              </div>
                            )}
                            <div>
                              <button
                                onClick={() => setNumberOfDays(0)}
                                className="edit-modal-clear-btn"
                              >
                                Clear
                              </button>
                              <div className="total-price">
                                <div className="price-amount">
                                  Total ${total}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {editModalStage === "payment" && (
                        <Elements options={options} stripe={stripePromise}>
                          <CheckoutForm saveJob={saveJob} />
                        </Elements>
                      )}
                    </DialogContent>
                    <DialogActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <div>
                        {" "}
                        <Button
                          sx={{ fontFamily: "Urbanist", color: "black" }}
                          onClick={handleEditModalClose}
                        >
                          Cancel
                        </Button>
                        {editModalStage !== "payment" && (
                          <React.Fragment>
                            {total > 0 && (
                              <Button
                                onClick={() => {
                                  fetchClientSecret();
                                }}
                                sx={{ fontFamily: "Urbanist", color: "black" }}
                              >
                                Checkout
                              </Button>
                            )}
                            {total === 0 && (
                              <Button
                                onClick={saveJob}
                                sx={{ fontFamily: "Urbanist", color: "black" }}
                              >
                                Save
                              </Button>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                      {editModalStage !== "payment" && (
                        <div style={{ display: "flex", gap: "10px" }}>
                          {editModalStage !== "jobs" && (
                            <button
                              onClick={() => setEditModalStage("jobs")}
                              className="edit-modal-btn"
                            >
                              Edit Details{" "}
                              <EditIcon sx={{ fontSize: "14px" }} />
                            </button>
                          )}
                          {editModalStage !== "duration" && (
                            <button
                              onClick={() => setEditModalStage("duration")}
                              className="edit-modal-btn"
                            >
                              Edit Duration{" "}
                              <TimelapseIcon sx={{ fontSize: "14px" }} />
                            </button>
                          )}
                          {editModalStage !== "promotion" && (
                            <button
                              onClick={() => setEditModalStage("promotion")}
                              className="edit-modal-btn"
                            >
                              Edit Promotion{" "}
                              <AdsClickIcon sx={{ fontSize: "14px" }} />
                            </button>
                          )}
                        </div>
                      )}
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              {value === "create" && <NewPost user={user} />}
            </div>
          </div>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Business;
