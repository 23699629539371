import React from "react";
import { useState, useEffect } from "react";
import { supabase } from "./SupabaseClient";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";

// Create a custom theme
const theme = createTheme({
  components: {
    Typography: {
      styleOverrides: {
        root: {
          fontFamily: "Urbanist", // Your desired fontFamily
          fontSize: "14px", // Your desired fontSize
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: "Urbanist", // Your desired fontFamily
          fontSize: "14px", // Your desired fontSize
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Target the root and change the border color
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black", // Change to your desired color
          },
        },
      },
    },
  },
});
const UpdatePassword = () => {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);

  const isPasswordValid = () => {
    const passwordPattern =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordPattern.test(newPassword)) {
      setErrorMessage(
        "Password must be at least 8 characters long, contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter."
      );
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return false;
    }
    return true;
  };

  const handleResetPasswordPress = (event) => {
    // Check if the pressed key is "Enter"
    if (event.key === "Enter") {
      // Prevent the default action to avoid submitting the form (if any)
      event.preventDefault();
      // Trigger the button click
      if (newPassword) {
        handlePasswordReset();
      }
    }
  };

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        setShowUpdatePassword(true);
      }
    });
  }, []);

  const handlePasswordReset = async () => {
    if (confirmPassword === "" || newPassword === "") {
      setErrorMessage("All fields are required");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }

    if (!isPasswordValid()) {
      return;
    }
    const { data, error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      setErrorMessage("Error with password reset");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    } else {
      navigate("/");
      setShowUpdatePassword(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {showUpdatePassword && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            justifyItems: "center",
            height: "100vh",
          }}
        >
          <div className="auth-container">
            <img
              alt="logo"
              src="/images/Logo.png"
              style={{
                display: { xs: "none", md: "flex" },
                marginRight: "10px",
                width: 80,
                height: 60,
                marginBottom: "30px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            ></img>

            <React.Fragment>
              <div className="auth-label-wrapper">
                <div className="auth-label">New Password</div>
                <TextField
                  fullWidth
                  type="password"
                  size="small"
                  variant="outlined"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="auth-label-wrapper">
                <div className="auth-label">Confirm Password</div>
                <TextField
                  onKeyDown={handleResetPasswordPress}
                  fullWidth
                  type="password"
                  size="small"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <button onClick={handlePasswordReset} className="auth-login-btn">
                Reset
              </button>
              {errorMessage && (
                <div className="auth-error-msg">{errorMessage}</div>
              )}
              <div
                onClick={() => navigate("/signin")}
                className="auth-bottom-nav"
              >
                Log In
              </div>
            </React.Fragment>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default UpdatePassword;
